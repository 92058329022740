import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Hidden as MuiHidden,
  Grid as MuiGrid,
  Button as MuiButton,
  Box as MuiBox,
  Slide as MuiSlide,
  Typography as MuiTypography,
} from "@mui/material";
import { useComponentsState } from "../../../../hooks";
import services from "../../../../services";

const Verify = () => {
  const navigate = useNavigate();
  const { loaderV1, alertV1 } = useComponentsState();
  const [otp, setOtp] = useState(["", "", "", "", ""]);
  const [waitTime, setWaitTime] = useState(60);
  const [mode, setMode] = useState("generate_email_verification_token");
  const inputRefs = useRef([]);
  const [formData, setFormData] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [lastTokenRequest, setLastTokenRequest] = useState();

  useEffect(() => {
    loaderV1.close();
  }, []);

  useEffect(() => {
    if (
      !searchParams.get("email_address") ||
      searchParams.get("email_address").length < 1
    ) {
      navigate(-1);
      return false;
    }

    if(searchParams.get('strategy') === 'create_directory'){
      searchParams.set('callback_url' , '/directory/create')
      setSearchParams(searchParams)
    }

    setFormData({
      ...formData,
      emailAddress: searchParams.get("email_address"),
    });
  }, []);

  useEffect(() => {
    setFormData({ ...formData, verificationToken: otp.join("") });
  }, [otp]);

  const handleInputChange = (e, index) => {
    const value = e.target.value;
    setOtp((prevOtp) => {
      const newOtp = [...prevOtp];
      console.log(newOtp);
      newOtp[index] = value;
      return newOtp;
    });
    if (value !== "") {
      if (index === otp.length - 1) {
        inputRefs.current[index].blur();
      } else {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "") {
      if (index === 0) {
        return;
      }
      inputRefs.current[index - 1].focus();
    }
  };
  return (
    <MuiGrid
      container={true}
      justifyContent={"center"}
      className="w-[100%] min-h-[100vh]"
    >
      <MuiHidden only={["sm", "xs"]}>
        <MuiGrid item={true} md={6} className="bg-[#0f0108]">
          <div className="flex items-center ml-[10%] mt-[40px] mb-5">
            <h1 className="text-[#c3b5bd]">shecluded</h1>
            <img
              className="w-[20.32px] h-[40.92px] pb-[7px] ml-[-2px]"
              src={""}
              alt=""
            />
          </div>
          <div className="w-[100%] flex flex-col items-center space-y-[40px] mt-4">
            <h1 className="text-[white] space-x-2 text-[32px] ml-[-17%] font-lato">
              <span>Powering</span>
              <span className="text-[#ED1F8E] font-playfair">Finance</span>
              <span>For Women</span>
            </h1>
          </div>
        </MuiGrid>
      </MuiHidden>

      <MuiGrid item={true} xs={12} sm={8} md={6}>
        <MuiSlide
          in={mode === "generate_email_verification_token"}
          mountOnEnter
          unmountOnExit
          exit={false}
          direction="left"
        >
          <MuiBox className="h-[100%] d-flex flex-column justify-content-center">
            <MuiBox className="text-center my-[20px]">
              <h1 className="text-[32px] font-lato">
                Verify your email address
              </h1>
            </MuiBox>
            <MuiBox className={"my-[20px]"}>
              <MuiTypography
                align="center"
                className="text-[16px] text-[#575757]"
              >
                {`We want to send a verification token to ${searchParams.get(
                  "email_address"
                )}`}
              </MuiTypography>
            </MuiBox>
            <MuiBox className={"w-[90%] md:w-[70%] mx-auto my-[20px]"}>
              <MuiButton
                disableElevation={true}
                variant="contained"
                className="w-[100%] bg-[#ED1F8E] text-capitalize"
                onClick={() => {
                  services.accounts.customers.emailAddress.verificationToken
                    .generate(searchParams.get("email_address"))
                    .then((data) => {
                      alertV1.open(data.message);
                      setMode("verify_email_address");
                    })
                    .catch((error) => {
                      alertV1.open(error.message);
                    });
                }}
              >
                {"send my verification token"}
              </MuiButton>
            </MuiBox>
          </MuiBox>
        </MuiSlide>
        <MuiSlide
          direction="left"
          exit={false}
          mountOnEnter
          unmountOnExit
          in={mode === "verify_email_address"}
        >
          <MuiBox className="h-[100%] d-flex flex-column justify-content-center">
            <MuiBox className="text-center my-[20px]">
              <h1 className="text-[32px] font-lato">
                Verify your email address
              </h1>
              <p className="text-[16px] text-[#575757]">
                Please enter the OTP sent to your mail
              </p>
            </MuiBox>
            <MuiBox className="flex justify-content-center my-[20px]">
              {otp.map((value, index) => {
                return (
                  <input
                    key={index}
                    className="border border-[#C2C3C8] mx-[5px] rounded-[10px] w-[40px] h-[40px] text-center"
                    type="text"
                    maxLength={1}
                    value={value}
                    onChange={(e) => handleInputChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    ref={(ref) => (inputRefs.current[index] = ref)}
                  />
                );
              })}
            </MuiBox>
            <MuiBox>
              {/* <p className="text-[16px] font-lato text-[#575757]">
              Resend code: 56s
            </p> */}
            </MuiBox>

            <MuiBox className={"w-[90%] md:w-[70%] mx-auto"}>
              <MuiButton
                disableElevation={true}
                variant="contained"
                className="w-[100%] bg-[#ED1F8E] text-capitalize"
                onClick={() => {
                  services.accounts.customers.emailAddress
                    .verify(
                      searchParams.get("email_address"),
                      formData.verificationToken
                    )
                    .then((data) => {
                      alertV1.open(data.message);

                      if (
                        searchParams.get("callback_url")
                      ) {
                        navigate(searchParams.get("callback_url"));
                        return false;
                      }
                      navigate("/onboard");
                    })
                    .catch((error) => {
                      alertV1.open(error.message);
                      return false;
                    });
                }}
              >
                {"Submit"}
              </MuiButton>
            </MuiBox>
          </MuiBox>
        </MuiSlide>
      </MuiGrid>
    </MuiGrid>
  );
};

export default Verify;
