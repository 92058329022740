import { useNavigate, Routes, Route, Navigate } from "react-router-dom";

import { Root, Apply, View, Calculator } from "./pages";

export default function Loans() {
  return (
    <Routes>
      <Route path={"/"} element={<Root />} />
      <Route path={"/apply"} element={<Apply />} />
      <Route path={"/view"} element={<View />} />
      <Route path={"/calculator"} element={<Calculator />} />
    </Routes>
  );
}
