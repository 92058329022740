import GlobalState from "./globalState";
import AlertState from "./alertState";
import CustomerState from "./customer";
import ComponentsState from "./components";

const state = {
  GlobalState,
  AlertState,
  CustomerState,
  ComponentsState,
};

export default state;
