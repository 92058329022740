import { Outlet } from "react-router-dom";

import { DashDirectory } from "../../../../components";

const Root = () => {
  return (
    <DashDirectory>
      <Outlet />
    </DashDirectory>
  );
};

export default Root;
