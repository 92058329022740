import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Button as MuiButton,
  Select as MuiSelect,
  MenuItem as MuiMenuItem,
} from "@mui/material/";

import { useCustomer as useCustomerState } from "../../hooks";
import { business_category } from "../../pages/Directory/pages/Create/script";
import business from "../../pages/Directory/pages/Root/Business";

const BusinessName = ({ activestep, changeActive, formData, setFormData }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [customerState, setCustomerState] = useCustomerState();

  const [selected, setSelected] = useState("");

  const handleClick = handleSubmit((data) => {
    changeActive(2, 1);
  });

  const countries = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo, Democratic Republic of the",
    "Congo, Republic of the",
    "Costa Rica",
    "Côte d’Ivoire",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "East Timor (Timor-Leste)",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea, North",
    "Korea, South",
    "Kosovo",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar (Burma)",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Macedonia",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Sudan, South",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States of America",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City (Holy See)",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];

  return (
    <section className={activestep === 1 ? "block " : "hidden"}>
      <h1 className="text-[20px] lg:text-[30px] text-center font-semibold text-black-1 font-playfair capitalize">
        Hello, {customerState.customer.firstName}👋🏼,{" "}
      </h1>
      <p className="text-[20px] lg:text-[30px] text-center font-semibold text-black-1 font-playfair">
        Lets’ get some details about your business
      </p>

      <form className="max-w-[400px] mx-auto mt-6">
        <div className="w-full">
          <label className="text-left text-[14px] font-medium text-gray-7 mb-1 block">
            What’s the Name of your Business
          </label>
          <input
            className="w-full text-[16px] border border-gray-3 focus:outline-0 focus:border-primary px-[14px] py-[10px] rounded"
            type="text"
            placeholder="Name of Business"
            {...register("business_name", { required: true })}
            onChange={(event) => {
              setFormData({
                ...formData,
                business: { ...formData.business, name: event.target.value },
              });
            }}
          />
          {errors.business_name && (
            <div className="text-red-400"> Business name is required </div>
          )}
        </div>

        <div className="w-full mt-3">
          <label className="text-left text-[14px] font-medium text-gray-7 mb-1 block">
            Business Category
          </label>

          <Controller
            control={control}
            name="business_category"
            defaultValue={formData.business.category}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <MuiSelect
                {...field}
                className="w-[100%] text-black h-[52px] border border-gray-3 focus:outline-0 focus:border-primary px-[14px] rounded"
                value={formData.business.category}
                onChange={(event) => {
                  setFormData({
                    ...formData,
                    business: {
                      ...formData.business,
                      category: event.target.value,
                    },
                  });
                }}
              >
                <MuiMenuItem disabled={true} value={""}>
                  <em className={"text-black"}>Select business category</em>
                </MuiMenuItem>
                {business_category?.map((item, index) => {
                  return (
                    <MuiMenuItem value={item} key={index}>
                      <div className="text-[16px]">{item}</div>
                    </MuiMenuItem>
                  );
                })}
              </MuiSelect>
            )}
          />

          {errors.business_category && (
            <p className="text-red-400">Choose a category </p>
          )}
        </div>

        <div className="w-full mt-3">
          <label className="text-left text-[14px] font-medium text-gray-7 mb-1 block">
            Country
          </label>
          <MuiSelect
            className="w-[100%] text-black h-[52px] border border-gray-3 focus:outline-0 focus:border-primary px-[14px] rounded"
            value={formData.country}
            onChange={(event) => {
              setFormData({
                ...formData,
               country: event.target.value
              });
            }}
          >
            {countries.map((item, index) => {
              return (
                <MuiMenuItem value={item} key={index}>
                  <div className="text-[16px]">{item}</div>
                </MuiMenuItem>
              );
            })}
          </MuiSelect>
        </div>

        <div className="text-center mt-5">
          <MuiButton
            onClick={handleClick}
            className="bg-primary w-full text-white capitalize text-[16px] py-[10px] px-[40px] rounded"
          >
            next
          </MuiButton>
        </div>
      </form>
    </section>
  );
};

export default BusinessName;
