const baseURL = process.env.REACT_APP_ACCOUNTS_SERVICE_BASE_URL;

const accounts = {
  auth: {
    signIn: {
      customer: (body) => {
        return new Promise((resolve, reject) => {
          fetch(`${baseURL}/auth/sign-in/customer?clientType=browser`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body),
          })
            .then((response) => {
              if (response.status === 200) {
                response.json().then((data) => {
                  resolve(data);
                });
              }
              if (response.status === 400) {
                response.json().then((error) => {
                  reject(error);
                });
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      },

      confirmationToken: {
        generate: () => {},
        verify: () => {},
      },
    },
    signUp: {
      customer: (body) => {
        return new Promise((resolve, reject) => {
          fetch(`${baseURL}/auth/sign-up/customer?clientType=browser`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body),
          })
            .then((response) => {
              if (response.status === 200) {
                response.json().then((data) => {
                  resolve(data);
                });
              }
              if (response.status === 400) {
                response.json().then((error) => {
                  reject(error);
                });
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
    },
  },
  customers: {
    onboard: (body) => {
      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/customers/onboard?clientType=browser`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
        })
          .then((response) => {
            if (response.status === 200) {
              response.json().then((data) => {
                resolve(data);
              });
            }
            if (response.status === 400) {
              response.json().then((error) => {
                reject(error);
              });
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    findOne: (by, value) => {
      return new Promise((resolve, reject) => {
        fetch(
          `${baseURL}/customers/find/one?by=${by}&id=${value}&email_address=${value}`
        )
          .then((response) => {
            if (response.status === 200) {
              response.json().then((data) => {
                resolve(data);
              });
            }
            if (response.status === 400) {
              response.json().then((error) => {
                reject(error);
              });
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    directory: {
      create: (body) => {
        return new Promise((resolve, reject) => {
          fetch(`${baseURL}/customers/directories/create`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body),
          })
            .then((response) => {
              if (response.status === 200) {
                response.json().then((data) => {
                  resolve(data);
                });
              }
              if (response.status === 400) {
                response.json().then((error) => {
                  reject(error);
                });
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      update: (id, operation, body) => {
        return new Promise((resolve, reject) => {
          fetch(
            `${baseURL}/customers/directories/update?id=${id}&operation=${operation}`,
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(body),
            }
          )
            .then((response) => {
              if (response.status === 200) {
                response.json().then((data) => {
                  resolve(data);
                });
              }
              if (response.status === 400) {
                response.json().then((error) => {
                  reject(error);
                });
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      products: {
        create: (id, body) => {
          return new Promise((resolve, reject) => {
            fetch(
              `${baseURL}/customers/directories/products/create?directory_id=${id}`,
              {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(body),
              }
            )
              .then((response) => {
                if (response.status === 200) {
                  response.json().then((data) => {
                    resolve(data);
                  });
                }
                if (response.status === 400) {
                  response.json().then((error) => {
                    reject(error);
                  });
                }
              })
              .catch((error) => {
                reject(error);
              });
          });
        },
        delete: (directoryID, productID) => {
          return new Promise((resolve, reject) => {
            fetch(
              `${baseURL}/customers/directories/products/delete?directory_id=${directoryID}&product_id=${productID}`,
              {
                method: "GET",
              }
            )
              .then((response) => {
                if (response.status === 200) {
                  response.json().then((data) => {
                    resolve(data);
                  });
                }
                if (response.status === 400) {
                  response.json().then((error) => {
                    reject(error);
                  });
                }
              })
              .catch((error) => {
                reject(error);
              });
          });
        },
      },
    },
    password: {
      resetToken: {
        generate: (emailAddress) => {
          return new Promise((resolve, reject) => {
            fetch(
              `${baseURL}/customers/password/reset-token/generate?email_address=${emailAddress}`
            )
              .then((response) => {
                if (response.status === 200) {
                  response.json().then((data) => {
                    resolve(data);
                  });
                }
                if (response.status === 400) {
                  response.json().then((error) => {
                    reject(error);
                  });
                }
              })
              .catch((error) => {
                reject(error);
              });
          });
        },
        verify: (emailAddress, resetToken) => {
          return new Promise((resolve, reject) => {
            fetch(
              `${baseURL}/customers/password/reset-token/verify?email_address=${emailAddress}&reset_token=${resetToken}`
            )
              .then((response) => {
                if (response.status === 200) {
                  response.json().then((data) => {
                    resolve(data);
                  });
                }
                if (response.status === 400) {
                  response.json().then((error) => {
                    reject(error);
                  });
                }
              })
              .catch((error) => {
                reject(error);
              });
          });
        },
      },
      reset: (emailAddress, newPassword) => {
        return new Promise((resolve, reject) => {
          fetch(
            `${baseURL}/customers/password/reset?email_address=${emailAddress}&new_password=${newPassword}`
          )
            .then((response) => {
              if (response.status === 200) {
                response.json().then((data) => {
                  resolve(data);
                });
              }
              if (response.status === 400) {
                response.json().then((error) => {
                  reject(error);
                });
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
    },
    emailAddress: {
      verificationToken: {
        generate: async function (emailAddress) {
          return new Promise((resolve, reject) => {
            fetch(
              `${baseURL}/customers/email-address/verification-token/generate?email_address=${emailAddress}`
            )
              .then((response) => {
                if (response.status === 200) {
                  response.json().then((data) => {
                    resolve(data);
                  });
                }
                if (response.status === 400) {
                  response.json().then((error) => {
                    reject(error);
                  });
                }
              })
              .catch((error) => {
                reject(error);
              });
          });
        },
      },
      verify: async function (emailAddress, verificationToken) {
        return new Promise((resolve, reject) => {
          fetch(
            `${baseURL}/customers/email-address/verify?email_address=${emailAddress}&verification_token=${verificationToken}`
          )
            .then((response) => {
              if (response.status === 200) {
                response.json().then((data) => {
                  resolve(data);
                });
              }
              if (response.status === 400) {
                response.json().then((error) => {
                  reject(error);
                });
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
    },
  },
};

export default accounts;
