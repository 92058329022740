import React, { useState, useEffect } from "react";
import {
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Divider as MuiDivider,
  Slide as MuiSlide,
  ListItem,
  Typography as MuiTypography,
} from "@mui/material";
import {
  GridView as MuiGridViewIcon,
  Portrait as MuiPortraitIcon,
  MenuBook as MuiMenuBookIcon,
  Settings as MuiSettingsIcon,
  ReceiptLong as MuiReceiptLongIcon,
  CreditCard as MuiCreditCardIcon,
  Logout as MuiLogoutIcon,
} from "@mui/icons-material";

import { sidebarItems } from "./script";

import { Link, useLocation, useNavigate } from "react-router-dom";

import { useBreakpoints, useComponentsState } from "../../hooks";

import "./style.css";
import { newShecludedLogo } from "../../assets/images";

const mainNavbarItems = [
  {
    id: 0,
    icon: MuiGridViewIcon,
    label: "Dashboard",
    route: "/customer-dashboard",
    handleClick: (navigate) => {
      navigate("/customer-dashboard");
    },
  },
  {
    id: 1,
    icon: MuiPortraitIcon,
    label: "Directory",
    route: "/directory",
    handleClick: (navigate) => {
      navigate("/directory");
    },
  },
  {
    id: 2,
    icon: MuiCreditCardIcon,
    label: "Loans",
    route: "/loans",
    handleClick: (navigate) => {
      navigate("/loans");
    },
  },
  {
    id: 3,
    icon: MuiMenuBookIcon,
    label: "Learn",
    route: "learn",
    handleClick: (navigate) => {
      navigate("/customer-dashboard");
    },
  },
  {
    id: 4,
    icon: MuiReceiptLongIcon,
    label: "Transactions",
    route: "transaction",
    handleClick: (navigate) => {
      navigate("/customer-dashboard");
    },
  },
  {
    id: 5,
    icon: MuiSettingsIcon,
    label: "Settings",
    route: "settings",
    handleClick: (navigate) => {
      navigate("/customer-dashboard");
    },
  },
];

const SidebarV1 = (props) => {
  const { pathname } = useLocation();
  const { componentsState, sidebarV1 } = useComponentsState();
  const breakpoint = useBreakpoints();
  const navigate = useNavigate();
  const [showSlide, setShowSlide] = useState(true);

  useEffect(() => {
    if (breakpoint === "xs" || breakpoint === "sm") {
      sidebarV1.close();
    } else {
      sidebarV1.open();
    }
  }, [breakpoint]);
  return (
    <MuiSlide
      in={componentsState.sidebarV1.isOpen}
      direction={"right"}
      mountOnEnter
      unmountOnExit
    >
      {/* <List className="py-0 px-[20px] mr-[20px]">
        <ListItemButton>
          <div>
            <img src={newShecludedLogo} alt="shecluded logo" />
          </div>
        </ListItemButton>
        {mainNavbarItems.map((item, index) => (
          <ListItemButton
            className={"px-[15px] rounded-md"}
            key={item.id}
            onClick={() => item.handleClick(navigate)}
            selected={props.selectedIndex === index}
            classes={{ selected: "text-white bg-[black]" }}
          >
            <ListItemIcon>
              <item.icon
                className={`${
                  props.selectedIndex === index
                    ? "text-[#ffffff]"
                    : "text-[black]"
                }`}
              />
            </ListItemIcon>
            <ListItemText
              primary={item.label}
              className={`${
                props.selectedIndex === index
                  ? "text-[#ffffff]"
                  : "text-[black]"
              }`}
            />
          </ListItemButton>
        ))}
        <MuiDivider />
        <ListItemButton
          className="px-[15px] rounded-md"
          onClick={() => {
            sessionStorage.clear();
            navigate("/");
            window.location.reload();
          }}
        >
          <ListItemIcon>
            <MuiLogoutIcon className={"text-danger"} />
          </ListItemIcon>
          <ListItemText primary={"Sign Out"} className={"text-danger"} />
        </ListItemButton>
      </List> */}
      <div className="h-[100vh] min-w-[100%] md:min-w-[20%] lg:min-w-[15%] overflow-y-hidden relative">
        <div className="h-[100vh] mx-auto">
          <Link to="/">
            <div className="px-2 flex items-center h-[60px]">
              <img
                className="mr-0.5 "
                src={
                  "https://res.cloudinary.com/dnegpzwod/image/upload/c_scale,h_20,q_100,w_20/v1682013650/assets/images/Vector_1_twlsug.png"
                }
                alt=""
              />
              <MuiTypography className="text-[20px]">shecluded</MuiTypography>
            </div>
          </Link>

          <ul className="px-2">
            {sidebarItems.map(({ Icon, ...item }) => (
              <Link
                key={item.title}
                to={item.link}
                className={`flex items-center rounded-lg py-2.5 pl-5
             cursor-pointer duration-100 text-sm font-medium mt-3 ${
               pathname.includes(item.link)
                 ? "bg-black-1 text-white"
                 : "text-black-1 hover:bg-gray-200"
             }`}
              >
                <div>
                  <Icon
                    fill={pathname.includes(item.link) ? "white" : "#202939"}
                  />
                </div>
                <span className="capitalize block ml-2">{item.title}</span>
              </Link>
            ))}
          </ul>
        </div>
      </div>
    </MuiSlide>
  );
};

export default SidebarV1;
