import { useEffect } from "react";
import { useNavigate, Routes, Route } from "react-router-dom";

import { Root, Create, Update } from "./pages";

import Business from "./pages/Root/Business";
import Product from "./pages/Root/Product";
import ProductId from "./pages/Root/ProductId";
import Messages from "./pages/Root/Messages";
import AddProduct from "./pages/Root/AddProduct";
import EditProduct from "./pages/Root/EditProduct";

import { useCustomer as useCustomerState } from "../../hooks";

export default function Directory() {
  const navigate = useNavigate();
  const [customerState] = useCustomerState();
  useEffect(() => {
    setTimeout(() => {
      if (customerState.customer.directory.isCreated === false) {
        navigate("/directory/create");
      }
    }, 200);
  }, [customerState]);

  return (
    <Routes>
      <Route path="/" element={<Root />}>
        <Route index path="/" element={<Business />} />
        <Route index path="/product" element={<Product />} />
        <Route index path="/product/:id" element={<ProductId />} />
        <Route index path="/product/add" element={<AddProduct />} />
        <Route index path="/product/edit/:id" element={<EditProduct />} />
        <Route index path="/messages" element={<Messages />} />
        <Route index path={"/update"} element={<Update />} />
      </Route>
      <Route path={"/create"} element={<Create />} />
      
    </Routes>
  );
}
