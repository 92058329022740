import alertContext from "./alertContext.js";
import customerContext from "./customer";
import componentsContext from "./components";

const contexts = {
  alertContext,
  customerContext,
  componentsContext,
};

export default contexts;
