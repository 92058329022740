import { dummyProductImg } from "../../../../assets/images";

export const products = [
  {
    id: 1,
    title: "Jollof Rice Tray",
    img: dummyProductImg,
    price: "NGN 5,000.00",
    description:
      "Lorem ipsum dolor sit amet consectetur. At id viverra ut duis. Mollis proin viverra malesuada mollis et netus. Sed facilisi diam facilisis pellentesque sem volutpat. Sit in egestas nulla ac libero arcu nulla sit lectus.",
  },
  {
    id: 2,
    title: "Vegan Meal Plan",
    img: dummyProductImg,
    price: "NGN 50,000.00",
    description:
      "Lorem ipsum dolor sit amet consectetur. At id viverra ut duis. Mollis proin viverra malesuada mollis et netus. Sed facilisi diam facilisis pellentesque sem volutpat. Sit in egestas nulla ac libero arcu nulla sit lectus.",
  },
  {
    id: 3,
    title: "Chicken Salad",
    img: dummyProductImg,
    price: "NGN 5,000.00",
    description:
      "Lorem ipsum dolor sit amet consectetur. At id viverra ut duis. Mollis proin viverra malesuada mollis et netus. Sed facilisi diam facilisis pellentesque sem volutpat. Sit in egestas nulla ac libero arcu nulla sit lectus.",
  },
  {
    id: 4,
    title: "Special Sandwish",
    img: dummyProductImg,
    price: "NGN 10,000.00",
    description:
      "Lorem ipsum dolor sit amet consectetur. At id viverra ut duis. Mollis proin viverra malesuada mollis et netus. Sed facilisi diam facilisis pellentesque sem volutpat. Sit in egestas nulla ac libero arcu nulla sit lectus.",
  },
  {
    id: 5,
    title: "Pancakes",
    img: dummyProductImg,
    price: "NGN 6,000.00",
    description:
      "Lorem ipsum dolor sit amet consectetur. At id viverra ut duis. Mollis proin viverra malesuada mollis et netus. Sed facilisi diam facilisis pellentesque sem volutpat. Sit in egestas nulla ac libero arcu nulla sit lectus.",
  },
  {
    id: 6,
    title: "Chicken Sharwama",
    img: dummyProductImg,
    price: "NGN 3,500.00",
    description:
      "Lorem ipsum dolor sit amet consectetur. At id viverra ut duis. Mollis proin viverra malesuada mollis et netus. Sed facilisi diam facilisis pellentesque sem volutpat. Sit in egestas nulla ac libero arcu nulla sit lectus.",
  },
];
