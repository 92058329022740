import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { RoutedTab } from '../../../../components/Directory';
import { addGrayIcon } from '../../../../assets';
import { useForm, Controller } from 'react-hook-form';

import {
  Button as MuiButton,
  Select as MuiSelect,
  Checkbox as MuiCheckbox,
  MenuItem as MuiMenuItem,
} from '@mui/material/';

import { uploadGrayIcon } from '../../../../assets';

import { business_category } from '../Create/script';

import { currencies } from '../../../../data/currency';

const EditProduct = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [sellingType, setSellingType] = useState(null);

  const [selected, setSelected] = useState(business_category[0]);

  const [selectedCurrency, setSelectedCurrency] = useState(currencies[0]?.code);

  const [imageFiles, setImageFiles] = useState([]);

  const sellingTypeData = [
    {
      id: 1,
      label: 'Walk-in Store',
    },
    {
      id: 2,
      label: 'Online Store',
    },
    {
      id: 3,
      label: 'Both online and walk-in store',
    },
  ];

  const handlePhotoChange = (e) => {
    const files = e.target.files;

    const arrayImages = Array.from(files);

    if (arrayImages?.length !== 0 && arrayImages?.length <= 3) {
      let baseArray = [];

      arrayImages.forEach((file) => {
        const reader = new FileReader();
        reader?.readAsDataURL(file);

        reader.onload = () => {
          const imagebase64String = reader.result;

          baseArray.push(imagebase64String);

          setImageFiles([...imageFiles, ...baseArray]);
        };
      });
    } else {
      return;
    }
  };

  return (
    <div className="py-3 px-2 md:px-6 font-dmSans ">
      <RoutedTab />

      <section className="my-5 md:flex items-start justify-between">
        <div>
          <h2 className="font-medium text-[20px] text-gray-9">Edit Product</h2>
          <p className="mt-1 text-[16px] font-medium text-gray-5">
            Lorem ipsum dolor sit amet consectetur.
          </p>
        </div>

        <Link to="/directory/product/add">
          <MuiButton className="mt-2 md:mt-0 flex items-center bg-white border border-gray-3 normal-case w-fit text-gray-5 text-[12px] lg:text-[16px] py-[6px] px-[10px] lg:px-[14px] rounded">
            <img src={addGrayIcon} alt="delete icon" />
            <span className="ml-1 md:ml-2">Add new products</span>
          </MuiButton>
        </Link>
      </section>

      <form className="">
        <section className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <article>
            <h3 className="text-gray-9 font-medium text-[16px]">
              Product Description
            </h3>

            <div className="mt-3 border border-gray-3 rounded py-[30px] px-[24px]">
              <div className="w-full">
                <label className="text-left text-[14px] font-medium text-gray-7 mb-1 block">
                  Product Name
                </label>
                <input
                  className="w-full border focus:outline-0 focus:border-primary text-[16px] border-gray-3 px-[14px] py-[10px] rounded"
                  type="text"
                  placeholder="Product Name"
                  {...register('productName', { required: true })}
                />{' '}
                {errors.productName && (
                  <div className="text-red-400"> Product name is required </div>
                )}
              </div>

              <div className="w-full mt-3">
                <label className="text-left text-[14px] font-medium text-gray-7 mb-1 block">
                  Product Description
                </label>
                <textarea
                  className="w-full border border-gray-3 focus:outline-0 focus:border-primary text-[16px] px-[14px] py-[10px] rounded"
                  type="text"
                  placeholder="Enter a description"
                  rows="5"
                  {...register('product_desc', { required: true })}
                ></textarea>
                {errors.product_desc && (
                  <div className="text-red-400">
                    {' '}
                    Product description is required{' '}
                  </div>
                )}
              </div>
            </div>
          </article>

          <article>
            <h3 className="text-gray-9 font-medium text-[16px] mb-3">
              Product Images
            </h3>

            <div className="py-[30px] px-[24px] bg-white border border-gray-3 rounded">
              <div className="p-3 bg-[#FCFCFD] flex flex-col items-center justify-center cursor-pointer relative">
                <div className="w-8 h-8 rounded-full bg-[#F2F4F7] flex items-center justify-center">
                  <img src={uploadGrayIcon} alt="upload icon" />
                </div>
                {imageFiles?.length >= 3 ? (
                  <div className="text-[14px] text-center mt-3 xl:w-1/2 mx-auto">
                    You have exceeded the maximum number of image upload
                  </div>
                ) : (
                  <div className="text-[14px] text-center mt-3 xl:w-1/2 mx-auto">
                    <span className="text-primary font-medium">
                      Click to upload
                    </span>{' '}
                    <span>
                      or drag and drop SVG, PNG, JPG or GIF (max. 800x400px)
                    </span>
                  </div>
                )}

                <input
                  disabled={imageFiles?.length >= 3}
                  multiple="multiple"
                  type="file"
                  accept={'.jpg, .png, .jpeg, .svg, .gif'}
                  max-file-size="2048"
                  onChange={handlePhotoChange}
                  className="cursor-pointer  w-full h-full absolute top-0 left-0 opacity-0"
                />
              </div>

              {imageFiles?.length !== 0 ? (
                <div className="mt-3 grid grid-col-1 md:grid-cols-3 gap-4">
                  {imageFiles.map((image, index) => (
                    <div key={index} className=" w-full relative h-20">
                      <img
                        className="object-cover h-full w-full "
                        src={image}
                        alt="user's profile"
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <div className="mt-3 grid grid-col-1 md:grid-cols-3 gap-4">
                  {[1, 2, 3].map((item, index) => (
                    <div
                      key={index}
                      className=" w-full relative h-20 bg-gray-3 text-white flex items-center justify-center"
                    >
                      Image
                    </div>
                  ))}
                </div>
              )}
            </div>
          </article>
        </section>

        <section className="mt-5 grid grid-cols-1 lg:grid-cols-2 gap-4">
          <div className="">
            <article className="">
              <h3 className="text-gray-9 font-medium text-[16px] mb-3">
                Category
              </h3>

              <div className=" border border-gray-3 rounded  py-[30px] px-[24px]">
                <div className="w-full">
                  <label className="text-left text-[14px] font-medium text-gray-7 mb-1 block">
                    Product Category
                  </label>

                  <Controller
                    control={control}
                    defaultValue={selected}
                    name="product_category"
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <MuiSelect
                        {...field}
                        className="w-[100%] h-[52px] border border-gray-3 focus:outline-0 focus:border-primary px-[14px] rounded"
                      >
                        {business_category?.map((item, index) => {
                          return (
                            <MuiMenuItem value={item} key={index}>
                              <div className="text-[16px]">{item}</div>
                            </MuiMenuItem>
                          );
                        })}
                      </MuiSelect>
                    )}
                  />

                  {errors.product_category && (
                    <p className="text-red-400">Choose a category </p>
                  )}
                </div>
              </div>
            </article>

            <article className="mt-4">
              <h3 className="text-gray-9 font-medium text-[16px] mb-3">
                Selling Type
              </h3>

              <div className=" border border-gray-3 rounded  p-[20px]">
                {sellingTypeData?.map((item) => (
                  <div key={item?.id} className="flex items-center mb-[1px]">
                    <MuiCheckbox
                      // checked={checked}
                      // onChange={handleChange}
                      // inputProps={{ 'aria-label': 'controlled' }}
                      size="small"
                      sx={{
                        color: '#667085',
                        '&.Mui-checked': {
                          color: '#667085',
                        },
                      }}
                    />
                    <label className="text-gray-7 text-[14px] font-medium">
                      {item?.label}
                    </label>
                  </div>
                ))}
              </div>
            </article>
          </div>

          <article>
            <h3 className="text-gray-9 font-medium text-[16px] mb-3">
              Pricing
            </h3>

            <div className=" border border-gray-3 rounded  py-[30px] px-[24px]">
              <div className="w-full">
                <label className="text-left text-[14px] font-medium text-gray-7 mb-1 block">
                  Amount
                </label>
                <input
                  className="w-full border border-gray-3 focus:outline-0 focus:border-primary text-[16px] px-[14px] py-[10px] rounded"
                  type="text"
                  placeholder="Amount"
                  {...register('amount ', { required: true })}
                />
                {errors.amount && (
                  <div className="text-red-400"> Amount is required </div>
                )}
              </div>

              <div className="w-full mt-4">
                <label className="text-left text-[14px] font-medium text-gray-7 mb-1 block">
                  Currency
                </label>

                <Controller
                  control={control}
                  defaultValue={selectedCurrency}
                  name="currency"
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <MuiSelect
                      {...field}
                      className="w-[100%] h-[52px] border border-gray-3 focus:outline-0 focus:border-primary px-[10px] rounded"
                    >
                      {currencies?.map((item, index) => {
                        return (
                          <MuiMenuItem value={item?.code} key={index}>
                            <div className="text-[16px]">{item?.code}</div>
                          </MuiMenuItem>
                        );
                      })}
                    </MuiSelect>
                  )}
                />

                {errors.currency && (
                  <div className="text-red-400"> Currency is required </div>
                )}
              </div>
            </div>

            <div className="text-right mt-4">
              <MuiButton className="normal-case  bg-primary w-fit text-white text-[16px] py-[10px] px-[14px] rounded">
                Edit product
              </MuiButton>
            </div>
          </article>
        </section>
      </form>
    </div>
  );
};

export default EditProduct;
