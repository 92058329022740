import state from ".";

function GlobalState(props) {
  return (
    <state.ComponentsState>
      <state.AlertState>
        <state.CustomerState>{props.children}</state.CustomerState>
      </state.AlertState>
    </state.ComponentsState>
  );
}

export default GlobalState;
