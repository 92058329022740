import { useContext } from "react";

import contexts from "../contexts";

function useComponentsState(props) {
  const [componentsState, setComponentsState] = useContext(
    contexts.componentsContext
  );

  const loaderV1 = {
    open: function () {
      setComponentsState((componentsState) => {
        return { ...componentsState, loaderV1: { isOpen: true } };
      });
    },
    close: function () {
      setComponentsState((componentsState) => {
        return { ...componentsState, loaderV1: { isOpen: false } };
      });
    },
  };

  const sidebarV1 = {
    open: function () {
      setComponentsState((componentsState) => {
        return { ...componentsState, sidebarV1: { isOpen: true } };
      });
    },
    close: function () {
      setComponentsState((componentsState) => {
        return { ...componentsState, sidebarV1: { isOpen: false } };
      });
    },
  };

  const alertV1 = {
    open: function (message = "", severity = "info") {
      setComponentsState((componentsState) => {
        return {
          ...componentsState,
          alertV1: { isOpen: true, message },
        };
      });
    },
    close: function () {
      setComponentsState((componentsState) => {
        return {
          ...componentsState,
          alertV1: { isOpen: false, message: "", severity: "info" },
        };
      });
    },
  };
  return { componentsState,setComponentsState, loaderV1, sidebarV1, alertV1 };
}

export default useComponentsState;
