import { useState, useEffect } from "react";
import contexts from "../contexts";

function AlertState(props) {
  const initialAlertState = {
    isOpen: false,
    message: "",
  };

  const [alertState, setAlertState] = useState(initialAlertState);

  useEffect(() => {
    console.log("ALERT STATE==>", alertState);
  }, [alertState]);
  return (
    <contexts.alertContext.Provider value={[alertState, setAlertState]}>
      {props.children}
    </contexts.alertContext.Provider>
  );
}

export default AlertState;
