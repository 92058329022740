import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { RoutedTab } from "../../../../components/Directory";
import { useForm, Controller } from "react-hook-form";

import {
  Button as MuiButton,
  Select as MuiSelect,
  Checkbox as MuiCheckbox,
  MenuItem as MuiMenuItem,
} from "@mui/material/";

import { uploadGrayIcon } from "../../../../assets";

import { business_category } from "../Create/script";

import { currencies } from "../../../../data/currency";

import services from "../../../../services";
import { useCustomer as useCustomerState } from "../../../../hooks";


const AddProduct = () => {
  const [submitProgress, setSubmitProgress] = useState(false)
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [customerState, setCustomerState] = useCustomerState();

  const [selected, setSelected] = useState(business_category[0]);

  const [selectedCurrency, setSelectedCurrency] = useState(
    currencies[110]?.code
  );

  const [imageFiles, setImageFiles] = useState([]);

  const [formData, setFormData] = useState({ currency: "NGN", images: [] });

  const handlePhotoChange = (e) => {
    const files = e.target.files;

    if (
      e.target.files.length > 5 ||
      formData.images.length + e.target.files.length > 5
    ) {
      alert("You have exceeded the limit of 5 files only!");
      return false;
    }

    const arrayImages = Array.from(files);

    let baseArray = [];

    for (const file of arrayImages) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        const imagebase64String = reader.result;

        setFormData((formData) => {
          let newFormData = { ...formData };
          if (formData.images.indexOf(imagebase64String) < 0) {
            newFormData.images = [...newFormData.images, imagebase64String];
          }

          return { ...newFormData };
        });

        baseArray.push(imagebase64String);

        setImageFiles([...imageFiles, ...baseArray]);
      };
    }
  };

  return (
    <div className="py-3 px-2 md:px-6 font-dmSans ">
      <RoutedTab />

      <section className="my-5">
        <h2 className="font-medium text-[20px] text-gray-9">Add Product</h2>
      </section>

      <form className="">
        <section className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <article>
            <h3 className="text-gray-9 font-medium text-[16px]">
              Product Description
            </h3>

            <div className="mt-3 border border-gray-3 rounded py-[30px] px-[24px]">
              <div className="w-full">
                <label className="text-left text-[14px] font-medium text-gray-7 mb-1 block">
                  Product Name
                </label>
                <input
                  className="w-full border focus:outline-0 focus:border-primary text-[16px] border-gray-3 px-[14px] py-[10px] rounded"
                  type="text"
                  placeholder="Product Name"
                  {...register("productName", { required: true })}
                  onChange={(event) => {
                    setFormData({ ...formData, name: event.target.value });
                  }}
                />{" "}
                {errors.productName && (
                  <div className="text-red-400"> Product name is required </div>
                )}
              </div>

              <div className="w-full mt-3">
                <label className="text-left text-[14px] font-medium text-gray-7 mb-1 block">
                  Product Description
                </label>
                <textarea
                  className="w-full border border-gray-3 focus:outline-0 focus:border-primary text-[16px] px-[14px] py-[10px] rounded"
                  type="text"
                  placeholder="Enter a description"
                  rows="5"
                  {...register("product_desc", { required: true })}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      description: event.target.value,
                    });
                  }}
                ></textarea>
                {errors.product_desc && (
                  <div className="text-red-400">
                    {" "}
                    Product description is required{" "}
                  </div>
                )}
              </div>
            </div>
          </article>

          <article>
            <h3 className="text-gray-9 font-medium text-[16px] mb-3">
              Product Images
            </h3>

            <div className="py-[30px] px-[24px] bg-white border border-gray-3 rounded">
              <div className="p-3 bg-[#FCFCFD] flex flex-col items-center justify-center cursor-pointer relative">
                <div className="w-8 h-8 rounded-full bg-[#F2F4F7] flex items-center justify-center">
                  <img src={uploadGrayIcon} alt="upload icon" />
                </div>
                {imageFiles?.length >= 5 ? (
                  <div className="text-[14px] text-center mt-3 xl:w-1/2 mx-auto">
                    You have exceeded the maximum number of image upload
                  </div>
                ) : (
                  <div className="text-[14px] text-center mt-3 xl:w-1/2 mx-auto">
                    <span className="text-primary font-medium">
                      Click to upload
                    </span>{" "}
                    <span>
                      or drag and drop SVG, PNG, JPG or GIF (max. 800x400px)
                    </span>
                  </div>
                )}

                <input
                  disabled={imageFiles?.length >= 5}
                  multiple="multiple"
                  type="file"
                  accept={".jpg, .png, .jpeg, .svg, .gif"}
                  max-file-size="2048"
                  onChange={handlePhotoChange}
                  className="cursor-pointer  w-full h-full absolute top-0 left-0 opacity-0"
                />
              </div>

              {formData.images?.length !== 0 ? (
                <div className="mt-3 grid grid-col-1 md:grid-cols-3 gap-4">
                  {formData.images.map((image, index) => (
                    <div key={index} className=" w-full relative h-20">
                      <img
                        className="object-cover h-full w-full "
                        src={image}
                        alt="user's profile"
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <div className="mt-3 grid grid-col-1 md:grid-cols-3 gap-4">
                  {[1, 2, 3, 4, 5].map((item, index) => (
                    <div
                      key={index}
                      className=" w-full relative h-20 bg-gray-3 text-white flex items-center justify-center"
                    >
                      Image
                    </div>
                  ))}
                </div>
              )}
            </div>
          </article>
        </section>

        <section className="mt-5 grid grid-cols-1 lg:grid-cols-2 gap-4">
          {/* <div className="">
            <article className="">
              <h3 className="text-gray-9 font-medium text-[16px] mb-3">
                Category
              </h3>

              <div className=" border border-gray-3 rounded  py-[30px] px-[24px]">
                <div className="w-full">
                  <label className="text-left text-[14px] font-medium text-gray-7 mb-1 block">
                    Product Category
                  </label>

                  <Controller
                    control={control}
                    defaultValue={selected}
                    name="product_category"
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <MuiSelect
                        {...field}
                        className="w-[100%] h-[52px] border border-gray-3 focus:outline-0 focus:border-primary px-[14px] rounded"
                      >
                        {business_category?.map((item, index) => {
                          return (
                            <MuiMenuItem value={item} key={index}>
                              <div className="text-[16px]">{item}</div>
                            </MuiMenuItem>
                          );
                        })}
                      </MuiSelect>
                    )}
                  />

                  {errors.product_category && (
                    <p className="text-red-400">Choose a category </p>
                  )}
                </div>
              </div>
            </article>

            <article className="mt-4">
              <h3 className="text-gray-9 font-medium text-[16px] mb-3">
                Selling Type
              </h3>

              <div className=" border border-gray-3 rounded  p-[20px]">
                {sellingTypeData?.map((item) => (
                  <div key={item?.id} className="flex items-center mb-[1px]">
                    <MuiCheckbox
                      // checked={checked}
                      // onChange={handleChange}
                      // inputProps={{ 'aria-label': 'controlled' }}
                      size="small"
                      sx={{
                        color: '#667085',
                        '&.Mui-checked': {
                          color: '#667085',
                        },
                      }}
                    />
                    <label className="text-gray-7 text-[14px] font-medium">
                      {item?.label}
                    </label>
                  </div>
                ))}
              </div>
            </article>
          </div> */}

          <article>
            <h3 className="text-gray-9 font-medium text-[16px] mb-3">
              Pricing
            </h3>

            <div className=" border border-gray-3 rounded  py-[30px] px-[24px]">
              <div className="w-full">
                <label className="text-left text-[14px] font-medium text-gray-7 mb-1 block">
                  Amount
                </label>
                <input
                  className="w-full border border-gray-3 focus:outline-0 focus:border-primary text-[16px] px-[14px] py-[10px] rounded"
                  type="text"
                  placeholder="Amount"
                  {...register("amount ", { required: true })}
                  onChange={(event) => {
                    setFormData({ ...formData, price: event.target.value });
                  }}
                />
                {errors.amount && (
                  <div className="text-red-400"> Amount is required </div>
                )}
              </div>

              <div className="w-full mt-4">
                <label className="text-left text-[14px] font-medium text-gray-7 mb-1 block">
                  Currency
                </label>

                <Controller
                  control={control}
                  defaultValue={selectedCurrency}
                  name="currency"
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <MuiSelect
                      {...field}
                      className="w-[100%] h-[52px] border border-gray-3 focus:outline-0 focus:border-primary px-[10px] rounded"
                      value={formData.currency}
                      onChange={(event) => {
                        setFormData({
                          ...formData,
                          currency: event.target.value,
                        });
                      }}
                    >
                      {currencies?.map((item, index) => {
                        return (
                          <MuiMenuItem value={item?.code} key={index}>
                            <div className="text-[16px]">{item?.name}</div>
                          </MuiMenuItem>
                        );
                      })}
                    </MuiSelect>
                  )}
                />

                {errors.currency && (
                  <div className="text-red-400"> Currency is required </div>
                )}
              </div>
            </div>

            <div className="text-right mt-4">
              <MuiButton
                disabled={submitProgress}
                onClick={(event) => {
                  setSubmitProgress(true)
                  services.accounts.customers.directory.products
                    .create(
                      customerState.customer.directory.directory._id,
                      formData
                    )
                    .then(() => {
                      alert("Product uploaded successfully!");
                      setSubmitProgress(false)
                    })
                    .catch((error) => {
                      alert(error.message);
                    });
                }}
                className="normal-case  bg-primary w-fit text-white text-[16px] py-[10px] px-[14px] rounded"
              >
               {submitProgress ? "Uploading..." : "Add product"}
              </MuiButton>
            </div>
          </article>
        </section>
      </form>
    </div>
  );
};

export default AddProduct;
