import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import {
  SignIn,
  SignOut,
  SignUp,
  Onboard,
  CustomerDashboard,
  Loans,
  Directory,
  EmailAddress,
  Password,
} from "./pages";

import state from "./state";

import { AlertV1, LoaderV1 } from "./components";

function App() {
  return (
    <state.GlobalState className={"absolute top-0 bottom-0 right-0 left-0"}>
      <BrowserRouter>
        <Routes>
          <Route path={"/"} element={<Navigate to={"/signin"} />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signout" element={<SignOut />} />
          <Route path="/onboard" element={<Onboard />} />
          <Route path={"/customer-dashboard"} element={<CustomerDashboard />} />
          <Route path={"/loans/*"} element={<Loans />} />
          <Route path={"/directory/*"} element={<Directory />} />
          <Route path={"/email-address/*"} element={<EmailAddress />} />
          <Route path={"/password/*"} element={<Password />} />
        </Routes>
      </BrowserRouter>
      <AlertV1 />
      {/* <LoaderV1 /> */}
    </state.GlobalState>
  );
}

export default App;
