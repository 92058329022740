import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

import { Typography as MuiTypography } from "@mui/material";

import "./stepper.css";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#DD2590",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#DD2590",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  fontSize: 10,
  alignItems: "center",
  ...(ownerState.active && {
    color: "black",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#DD2590",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "#EAECF0",
  },
  "& .QontoStepIcon-activeIcon": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "#ED1F8E",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <div className="bg-[#F9FAFB] h-[24px] w-[24px] rounded-full flex items-center justify-center">
          <Check className="QontoStepIcon-completedIcon" />
        </div>
      ) : active ? (
        <div className="bg-[#F9FAFB] h-[24px] w-[24px] rounded-full flex items-center justify-center">
          <div className="QontoStepIcon-activeIcon" />
        </div>
      ) : (
        <div className="bg-[#F9FAFB] h-[24px] w-[24px] rounded-full flex items-center justify-center">
          <div className="QontoStepIcon-circle" />
        </div>
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const steps = [
  "Business Details",
  "About Business",
  "Product/Service",
  "Social Media",
  "Personal Profile",
];

const Steppers = ({ completedstep }) => {
  return (
    <div className="mb-10 w-full xl:w-10/12 mx-auto text-[14px] overflow-x-auto whitespace-nowrap">
      <Stepper
        alternativeLabel
        activeStep={completedstep}
        connector={<QontoConnector />}
      >
        {steps.map((label) => (
          <Step className="text-[14px]" key={label}>
            <StepLabel StepIconComponent={QontoStepIcon}>
              <MuiTypography className="text-[14px] hidden sm:inline">{label}</MuiTypography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default Steppers;
