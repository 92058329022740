import React from 'react';
import {
  useCustomer as useCustomerState,
  useComponentsState,
} from '../../hooks';

import { Avatar as MuiAvatar } from '@mui/material';

import { dropdownIcon, notificationGrayIcon } from '../../assets';

const HeaderV2 = () => {
  const { componentsState, sidebarV1 } = useComponentsState();
  const [customerState, setCustomerState] = useCustomerState();
  return (
    <header className="py-[20px] px-[32px] flex items-center bg-[#fff] ">
      <div className="ml-auto  flex items-center gap-x-4">
        <div>
          <img src={notificationGrayIcon} alt="dropdown icon" />
        </div>

        <div className="h-[48px] w-[48px] bg-gray-200 rounded-full relative overflow-hidden">
          <MuiAvatar
            className="h-full w-full object-cover"
            src=""
            alt="user's profilepic"
          />
        </div>

        <div className="cursor-pointer flex items-center capitalize text-[14px] font-medium text-[#202939]">
          <div className="mr-2">{`${customerState.customer.firstName}`}</div>

          <img src={dropdownIcon} alt="dropdown icon" />
        </div>
      </div>
    </header>
  );
};

export default HeaderV2;
