import React from 'react';
import { Link } from 'react-router-dom';

import { RoutedTab, ProductCard } from '../../../../components/Directory';
import { products } from './dummyData';
import { Button as MuiButton } from '@mui/material/';

import { useCustomer as useCustomerState } from '../../../../hooks';

import { addGrayIcon } from '../../../../assets';

const Product = () => {
  const [customerState, setCustomerState] = useCustomerState();
  return (
    <div className="pt-[70px] px-2 md:px-6 font-dmSans ">
      <RoutedTab />

      <div className="my-5 md:flex items-center justify-between">
        <div>
          <h2 className="font-medium text-[20px] text-gray-9">Product</h2>
        </div>

        <Link to="/directory/product/add">
          <MuiButton className=" mt-2 md:mt-0 flex items-center bg-white border border-gray-3 normal-case w-fit text-gray-5 text-[12px] lg:text-[16px] py-[6px] px-[10px] lg:px-[14px] rounded">
            <img src={addGrayIcon} alt="delete icon" />
            <span className="ml-1 md:ml-2">Add new products</span>
          </MuiButton>
        </Link>
      </div>

      <section className="my-5 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3  gap-5">
        {customerState.customer.directory.directory.products.map((item) => (
          <ProductCard key={item._id} item={item} />
        ))}
      </section>
    </div>
  );
};

export default Product;
