import { useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import logo from "../../assets/logo.svg";
import { useAlert, useCustomer, useComponentsState } from "../../hooks";

import services from "../../services";

export const SignIn = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [customerState, setCustomerState] = useCustomer();
  const { loaderV1, alertV1 } = useComponentsState();
  const _alert = useAlert();
  const navigate = useNavigate();
  const [pageReadyState, setPageReadyState] = useState(0);

  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (pageReadyState === 1) {
      setTimeout(() => {
        loaderV1.close();
      }, 500);
    }
  }, [pageReadyState]);

  useEffect(() => {
    setTimeout(() => {
      setPageReadyState(pageReadyState + 1);
    }, 500);

    return () => {
      loaderV1.open();
    };
  }, []);

  return (
    pageReadyState === 1 && (
      <div className="absolute top-0 bottom-0 left-0 right-0 flex">
        <div className="hidden md:block w-[50%] h-[100%] bg-[#0f0108] overflow-hidden">
          <div className="flex items-center ml-[10%] mt-[40px] mb-5">
            <h1 className="text-[#c3b5bd]">shecluded</h1>
            <img
              className="w-[20.32px] h-[40.92px] pb-[7px] ml-[-2px]"
              src={logo}
              alt=""
            />
          </div>
          <div className="w-[100%] flex flex-col items-center space-y-[40px] mt-4">
            <div className="text-center text-[white] space-x-2 text-[32px] font-lato">
              <span>Powering</span>
              <span className="text-[#ED1F8E] font-playfair">Finance</span>
              <span>For Women</span>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center md:w-[50%] w-[100%] h-[100%] bg-[white]">
          <div className="flex flex-col items-center w-[60%] space-y-[20px]">
            <div>
              <p className="text-[24px] md:text-[32px] font-playfair font-semibold text-center">
                {" Sign in to your Account"}
              </p>
            </div>
            <form className="w-[100%]">
              <div className="flex flex-col w-[100%] space-y-5">
                <div className="space-y-2">
                  <label>Email</label>
                  <br />
                  <input
                    className="w-[100%] border border-[#C2C3C8] h-[52px] rounded-[8px] pl-5"
                    type="email"
                    id="email"
                    name="email"
                    onChange={(event) => {
                      setFormData({
                        ...formData,
                        emailAddress: event.target.value,
                      });
                    }}
                  />
                </div>
                <div className="space-y-2">
                  <label>Password</label>
                  <br />
                  <input
                    className="w-[100%] border border-[#C2C3C8] h-[52px] rounded-[8px] pl-5"
                    type="password"
                    id="password"
                    name="password"
                    onChange={(event) => {
                      setFormData({
                        ...formData,
                        password: event.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </form>
            <button
              type="submit"
              onClick={() => {
                services.accounts.auth.signIn
                  .customer(formData)
                  .then((data) => {
                    alertV1.open(data.message);
                    setCustomerState({
                      ...customerState,
                      isSignedIn: true,
                      accessToken: data.data.accessToken,
                      refreshToken: data.data.refreshToken,
                      customer: data.data.customer,
                    });
                    sessionStorage.setItem("customer", data.data.customer._id);
                    if (data.data.customer.hasVerifiedEmailAddress === false) {
                      navigate(
                        `/email-address/verify?email_address=${
                          data.data.customer.emailAddress
                        }&callback_url=${
                          searchParams.get("strategy") === "directory"
                            ? "/directory"
                            : "/customer-dashboard"
                        }`
                      );
                      return false;
                    }
                    if (searchParams.get("strategy") === "directory") {
                      navigate("/directory");
                      return false;
                    }
                    navigate("/customer-dashboard");
                  })
                  .catch((error) => {
                    alertV1.open(error.message);
                  });
              }}
              className="w-[100%] h-[52px] text-center bg-[#ED1F8E] rounded-[8px] text-[white]"
            >
              Sign In
            </button>
            <div>
              <p className="text-center">
                Forgot your password?{" "}
                <span className="text-[#ED1F8E]">
                  <Link to="/password/reset">Reset</Link>
                </span>
              </p>
            </div>
            <div>
              <p className="text-center">
                Don't have an account?{" "}
                <span className="text-[#ED1F8E]">
                  <Link to="/signup">Sign Up</Link>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default SignIn;
