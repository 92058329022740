import React, { useState, useEffect } from 'react';

import HeaderV2 from '../../../../components/HeaderV2';
import { Button as MuiButton } from '@mui/material/';
import { ArrowBackIos as MuiArrowBackIos } from '@mui/icons-material';
import {
  BusinessName,
  BusinessBio,
  PersonalInfo,
  Product,
  SocialMedia,
  Steppers,
} from '../../../../components/Directory';

import { directoryProfile1 } from '../../../../assets/images';

const Create = () => {
  const [activestep, setActiveStep] = useState(0);
  const [completedstep, setCompletedStep] = useState(null);

  const changeActive = (step, completed) => {
    setActiveStep(step);
    setCompletedStep(completed);
  };

  const [formData, setFormData] = useState({
    products: [],
    business: { socialMedia: {}, category:"Other" },
    country: "Nigeria"
  });

  useEffect(() => {
    console.log('form--data', formData);
  }, [formData]);

  return (
    <div>
      <HeaderV2 />

      <div className="py-12 px-2">
        {activestep !== 0 && (
          <Steppers activestep={activestep} completedstep={completedstep} />
        )}

        <div
          className={`lg:w-1/2 mx-auto mb-3  ${
            activestep < 1 ? 'hidden' : 'block'
          }`}
        >
          <MuiButton
            startIcon={<MuiArrowBackIos />}
            className={'!capitalize !text-[black]'}
            onClick={() => {
              changeActive(activestep - 1, completedstep - 1);
            }}
          >
            Back
          </MuiButton>
        </div>

        <section className={activestep === 0 ? 'block text-center' : 'hidden'}>
          <img
            className="mx-auto"
            src={directoryProfile1}
            alt="directory profile default"
          />

          <h3 className=" font-playfair text-[20px] md:text-[24px] font-medium my-4">
            Holla, looks like you’re new here
          </h3>

          <MuiButton
            onClick={() => changeActive(1)}
            className="!bg-primary w-[250px] text-white capitalize text-[16px] py-[10px] px-[40px] rounded"
          >
            Get started
          </MuiButton>
        </section>

        <BusinessName
          completedstep={completedstep}
          activestep={activestep}
          changeActive={changeActive}
          formData={formData}
          setFormData={setFormData}
        />

        <BusinessBio
          completedstep={completedstep}
          activestep={activestep}
          changeActive={changeActive}
          formData={formData}
          setFormData={setFormData}
        />

        <Product
          completedstep={completedstep}
          activestep={activestep}
          changeActive={changeActive}
          formData={formData}
          setFormData={setFormData}
        />

        <SocialMedia
          completedstep={completedstep}
          activestep={activestep}
          changeActive={changeActive}
          formData={formData}
          setFormData={setFormData}
        />

        <PersonalInfo
          completedstep={completedstep}
          activestep={activestep}
          changeActive={changeActive}
          formData={formData}
          setFormData={setFormData}
        />
      </div>
    </div>
  );
};

export default Create;
