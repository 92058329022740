import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button as MuiButton,
  IconButton as MuiIconButton,
} from '@mui/material/';
import { Cancel as MuiCancel } from '@mui/icons-material';

import { uploadGrayIcon } from '../../assets';
import { useComponentsState } from '../../hooks';

import { currencies } from '../../data/currency';

const Product = ({ activestep, changeActive, formData, setFormData }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [selectedCurrency] = useState(currencies[110]?.code);

  const [product, setProduct] = useState({ images: [] });

  const { alertV1 } = useComponentsState();

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];

    const fileSize = file.size;
    const maxSize = 1 * 1024 * 1024; // 2 MB

    if (fileSize > maxSize) {
      alert('You exceeded the upload limit of 1mb');
      return false;
    }

    if (product.images.length === 5) {
      alert('You have maxed out the limit of 5 images');
      return false;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      const base64String = reader.result;
      setProduct({ ...product, images: [...product.images, base64String] });
    };
    reader.onerror = (error) => {
      console.error(error);
    };
  };

  const handleClick = handleSubmit((data) => {
    alert('Product/Service added successfully!');
    changeActive(4, 3);
  });

  useEffect(()=>{
    console.log("product--data", product)
  }, [product])

  return (
    <section className={activestep === 3 ? 'block ' : 'hidden'}>
      <p className="text-[20px] lg:text-[30px] text-center font-semibold text-black-1 font-playfair">
        Upload your products/Services
      </p>

      <form className="max-w-[400px] mx-auto mt-6">
        <div className="w-full">
          <label className="text-left text-[14px] font-medium text-gray-7 mb-1 block">
            Product Name
          </label>
          <input
            className="w-full border focus:outline-0 focus:border-primary text-[16px] border-gray-3 px-[14px] py-[10px] rounded"
            type="text"
            placeholder="Product Name"
            {...register('name', { required: true })}
            onChange={(event) => {
              setProduct({ ...product, name: event.target.value });
            }}
          />{' '}
          {errors.name && (
            <div className="text-red-400"> Product name is required </div>
          )}
        </div>

        <div className="w-full my-4">
          <label className="text-left text-[14px] font-medium text-gray-7 mb-1 block">
            Price
          </label>

          <div className="flex items-start h-[50px] border border-gray-3 focus:outline-0 focus-within:border-primary px-[10px] rounded">
            <select
              {...register('currency', { required: true })}
              defaultValue={selectedCurrency}
              className="h-[50px] bg-transparent text-[14px] border-0 focus:border-0 outline-0 shadow-0 text-gray-7"
              onChange={(event)=>
              {
                setProduct({ ...product, currency: event.target.value })
              }
              }
            >
              {currencies?.map((item, index) => {
                return (
                  <option value={item?.code} key={index}>
                    {item?.name}
                  </option>
                );
              })}
            </select>

            <input

              placeholder={"0.00"}
              className="flex-1 h-full bg-transparent px-2 border-0 outline-0  shadow-0 text-[14px] text-gray-7"
              type="text"
              {...register('price', { required: true })}
              onChange={(event)=>
              {
                setProduct({ ...product, price: event.target.value })
              }
            }
            />
          </div>
          {errors.price && (
            <div className="text-red-400"> Price is required </div>
          )}
        </div>

        <div className="w-full">
          <label className="text-left text-[14px] font-medium text-gray-7 mb-1 block">
            Product Description
          </label>
          <textarea
            className="w-full border border-gray-3 focus:outline-0 focus:border-primary text-[16px] px-[14px] py-[10px] rounded"
            type="text"
            placeholder="Enter a description"
            rows="5"
            {...register('product_desc', { required: true })}
            onChange={(event) => {
              setProduct({ ...product, description: event.target.value });
            }}
          ></textarea>
          {errors.product_desc && (
            <div className="text-red-400">Product description is required </div>
          )}
        </div>

        <div className="p-5 my-5 bg-white border border-gray-2 rounded-lg flex flex-col items-center justify-center cursor-pointer relative">
          <div>
            <div className="w-8 h-8 mx-auto rounded-full bg-[#F2F4F7] flex items-center justify-center">
              <img src={uploadGrayIcon} alt="upload icon" />
            </div>

            <div className="text-[14px] text-center mt-3">
              <span className="text-primary font-medium">Click to upload</span>{' '}
              <span>
                or drag and drop SVG, PNG, JPG or GIF (max. 800x400px)
              </span>
            </div>
          </div>

          <input
            type="file"
            accept={'.jpg, .png, .jpeg, .svg, .gif'}
            max-file-size="2048"
            onChange={handlePhotoChange}
            className="cursor-pointer  w-full h-full absolute top-0 left-0 opacity-0"
          />
        </div>

        <div className={'flex mt-[35px]'}>
          {product.images.map((item, index) => {
            return (
              <div
                key={index}
                className={
                  'w-[200px] mx-[4px] !rounded-lg border-[2px] !border-primary position-relative'
                }
              >
                <MuiIconButton
                  onClick={() => {
                    setProduct({
                      ...product,
                      images: [
                        ...product.images.filter(
                          (item2, index2) => index !== index2,
                        ),
                      ],
                    });
                  }}
                  className={'position-absolute right-[-12px] top-[-40px]'}
                >
                  <MuiCancel className={'!text-primary'} />
                </MuiIconButton>
                <img
                  src={item}
                  alt={item}
                  className={'h-[100%] w-[100%] object-cover'}
                />
              </div>
            );
          })}
        </div>

        <div className="text-center mt-5">
          <MuiButton
            onClick={handleClick}
            className="!bg-primary w-full text-white !capitalize text-[16px] py-[10px] px-[40px] rounded"
          >
            Upload
          </MuiButton>
          <MuiButton
            onClick={() => {
              changeActive(4, 3);
            }}
            className="!bg-[black] w-full text-white !capitalize !mt-[10px] text-[16px] py-[10px] px-[40px] rounded"
          >
            skip
          </MuiButton>
        </div>
      </form>
    </section>
  );
};

export default Product;
