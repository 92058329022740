import React from 'react';
import { Link } from 'react-router-dom';
import {
  useCustomer as useCustomerState,
  useComponentsState,
} from '../../hooks';
import {
  AppBar as MuiAppBar,
  Box as MuiBox,
  Avatar as MuiAvatar,
  Typography as MuiTypography,
  Grid as MuiGrid,
  Container as MuiContainer,
  Toolbar as MuiToolbar,
  IconButton as MuiIconButton,
  Menu as MuiMenu,
  Tooltip as MuiTooltip,
  Hidden as MuiHidden,
  Avatar,
} from '@mui/material';
import {
  Adb as MuiAdbIcon,
  Menu as MuiMenuIcon,
  Close as MuiCloseIcon,
} from '@mui/icons-material';
import './style.css';
import { dropdownIcon, notificationGrayIcon } from '../../assets';

const HeaderV1 = () => {
  const { componentsState, sidebarV1 } = useComponentsState();
  const [customerState, setCustomerState] = useCustomerState();
  return (
    <header className="z-50 right-0 top-0 left-0 px-2 lg:px-[32px] flex items-center bg-[#fff] fixed w-[100%] h-[60px]">
     <MuiBox className={"flex"}>
      <MuiHidden mdUp>
        <MuiIconButton
          onClick={() => {
            if (componentsState.sidebarV1.isOpen) {
              sidebarV1.close();
            } else {
              sidebarV1.open();
            }
          }}
        >
          {componentsState.sidebarV1.isOpen ? (
            <MuiCloseIcon />
          ) : (
            <MuiMenuIcon />
          )}
        </MuiIconButton>
      </MuiHidden>
      <Link to="/">
          <div className="px-2 flex items-center h-[60px]">
            <img
              className="mr-0.5 "
              src={
                "https://res.cloudinary.com/dnegpzwod/image/upload/c_scale,h_20,q_100,w_20/v1682013650/assets/images/Vector_1_twlsug.png"
              }
              alt=""
            />
            <MuiTypography className="text-[20px]">shecluded</MuiTypography>
          </div>
        </Link>
     </MuiBox>
      
      <div className="ml-auto  flex items-center gap-x-4">
        <div>
          <img src={notificationGrayIcon} alt="dropdown icon" />
        </div>

        <div className="h-[48px] w-[48px] bg-gray-200 rounded-full relative overflow-hidden">
          <Avatar
            className="h-full w-full object-cover"
            src=""
            alt="user's profilepic"
          />
        </div>

        <div className="cursor-pointer flex items-center capitalize text-[14px] font-medium text-[#202939]">
          <div className="mr-2">{`${customerState.customer?.firstName}`}</div>

          <img src={dropdownIcon} alt="dropdown icon" />
        </div>
      </div>
    </header>
  );
};

export default HeaderV1;
