import React from "react";
import { useForm } from "react-hook-form";
import { Button as MuiButton } from "@mui/material/";

const BusinessBio = ({ activestep, changeActive, formData, setFormData }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleClick = handleSubmit((data) => {
    changeActive(3, 2);
  });

  return (
    <section className={activestep === 2 ? "block " : "hidden"}>
      <p className="text-[20px] lg:text-[30px] text-center font-semibold text-black-1 font-playfair">
        Tell us about your business
      </p>

      <form className="max-w-[400px] mx-auto mt-6">
        <div className="w-full">
          <label className="text-left text-[14px] font-medium text-gray-7 mb-1 block">
            Description
          </label>
          <textarea
            className="w-full border border-gray-3 focus:outline-0 focus:border-primary text-[16px] px-[14px] py-[10px] rounded"
            type="text"
            placeholder="Enter a description"
            rows="5"
            {...register("business_bio", { required: true })}
            onChange={(event)=>{
              setFormData({...formData, business: {...formData.business, story: event.target.value}})
            }}
          ></textarea>
          {errors.business_bio && (
            <div className="text-red-400"> Business bio is required </div>
          )}
        </div>

        <div className="text-center mt-5">
          <MuiButton
            onClick={handleClick}
            className="!bg-primary w-full text-white capitalize text-[16px] py-[10px] px-[40px] rounded"
          >
            next
          </MuiButton>
        </div>
      </form>
    </section>
  );
};

export default BusinessBio;
