import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useComponentsState,
  useCustomer as useCustomerState,
} from '../../hooks';
import {
  Box,
  Typography,
  Card,
  Avatar,
  Button,
  Grid as MuiGrid,
  List as MuiList,
  ListItem as MuiListItem,
  ListItemText as MuiListItemText,
  ListItemButton as MuiListItemButton,
  ListItemIcon as MuiListItemIcon,
} from '@mui/material/';

import {
  Portrait as MuiPortraitIcon,
  MenuBook as MuiMenuBookIcon,
  CreditCard as MuiCreditCardIcon,
} from '@mui/icons-material';

import { SidebarV1, HeaderV1 } from '../../components';
import EastIcon from '@mui/icons-material/East';

import services from '../../services';

export default function CustomerDashboard() {
  const navigate = useNavigate();
  const { loaderV1, alertV1 } = useComponentsState();
  const [customerState, setCustomerState] = useCustomerState();
  const [pageReadyState, setPageReadyState] = useState(0);

  useEffect(() => {
    if (pageReadyState === 1) {
      loaderV1.close();
    }
  }, [pageReadyState]);

  useEffect(() => {
    const customerID = sessionStorage.getItem('customer');
    if (!customerID) {
      navigate('/signin');
      return false;
    }
    services.accounts.customers
      .findOne('id', customerID)
      .then((data) => {
        alertV1.open(data.message);
        setCustomerState((customerState) => {
          return {
            ...customerState,
            customer: data.data,
          };
        });
      })
      .catch((error) => {
        alertV1.open(error.message);
      });
    loaderV1.close();
    setPageReadyState(1);
    return () => {
      loaderV1.close();
    };
  }, []);

  return (
    <div className="flex overflow-x-hidden">
      <SidebarV1 selectedIndex={0} />

      <MuiGrid className='position-relative h-[100vh] overflow-y-hidden flex-grow-1'>
        <HeaderV1 />

        <MuiGrid item={true} className={'bg-white py-[20px] h-[100vh] overflow-y-scroll pt-[70px]'}>
          <Box className={'px-[30px]'}>
            <Box>
              <Typography className="text-[24px] text-capitalize">
                {`Hello, ${customerState.customer.firstName} 👋🏼`}
              </Typography>
              <Typography sx={{ color: 'gray' }}>
                Welcome to your dashboard
              </Typography>
            </Box>
            <Box
              className={'my-[30px] py-[30px] px-[20px]'}
              sx={{
                width: '100%',
                backgroundImage:
                  'url(https://res.cloudinary.com/geergregrgege/image/upload/v1679653877/assets/images/Wallet_png_1_iazeru.png)',
                backgroundSize: 'cover',
                borderRadius: '8px',
              }}
            >
              <Box>
                <Typography className={'font-lato text-white'}>
                  Wallet balance
                </Typography>
                <Typography sx={{ fontSize: '38px', color: '#FFFFFF' }}>
                  ₦0
                </Typography>
              </Box>
              <Box sx={{ display: 'flex' }}>
                <Button
                  variant="contained"
                  disableElevation={true}
                  className={'bg-white text-dark mr-[10px] text-capitalize'}
                >
                  Top up
                </Button>
                <Button
                  disableElevation={true}
                  variant={'outlined'}
                  className={'border-[#ffffff] text-[#ffffff] text-capitalize'}
                >
                  Withdraw
                </Button>
              </Box>
            </Box>
            <MuiGrid container={true} spacing={2}>
              <MuiGrid item={true} xs={12} sm={12} md={6}>
                <Card className="p-[20px] bg-[#FCE9F4]">
                  <h1 className="text-[16px] font-lato font-bold text-[#0E0008]">
                    Verify your identity
                  </h1>
                  <div className="flex space-x-1 mt-2 text-[#575757]">
                    <p>
                      Get access to loans and directory when you complete your
                      profile
                    </p>
                    <EastIcon />
                  </div>
                </Card>
              </MuiGrid>
              <MuiGrid item={true} xs={12} sm={12} md={6}>
                <Card className="w-[100%] p-[20px] bg-[#FBF5E5]">
                  <h1 className="text-[16px] font-lato font-bold text-[#0E0008]">
                    Complete your profile
                  </h1>
                  <div className="flex space-x-1 mt-2 text-[#575757]">
                    <p>
                      Get access to loans and directory when you complete your
                      profile
                    </p>
                    <EastIcon />
                  </div>
                </Card>
              </MuiGrid>
            </MuiGrid>
            <div className="w-[100%] mt-[30px] space-y-4">
              <h1 className="text-[16px] font-lato">Quick Actions</h1>
              <MuiList sx={{ width: '100%' }}>
                <MuiListItem className="p-0 m-0">
                  <MuiListItemButton
                    className="px-[20px] py-[20px]"
                    onClick={() => {
                      navigate('/loans');
                    }}
                  >
                    <MuiListItemIcon className="">
                      <Avatar className={'bg-[#FFEDF7]'}>
                        <MuiCreditCardIcon className={'text-[#ED1F8E]'} />
                      </Avatar>
                    </MuiListItemIcon>
                    <Box>
                      <h1 className="text-[16px] font-lato text-[#0E0008]">
                        {'Loans'}
                      </h1>
                      <p className="text-[14px] text-lato text-[#575757]">
                        {'Apply for loans and get money in 20 minutes'}
                      </p>
                    </Box>
                  </MuiListItemButton>
                </MuiListItem>
                <MuiListItem className="p-0 m-0">
                  <MuiListItemButton
                    className="px-[20px] py-[20px]"
                    onClick={() => {
                      //navigate("/loans");
                    }}
                  >
                    <MuiListItemIcon className="">
                      <Avatar className={'bg-[#FFE0D1]'}>
                        <MuiMenuBookIcon className={'text-[#FF7733]'} />
                      </Avatar>
                    </MuiListItemIcon>
                    <Box>
                      <h1 className="text-[16px] font-lato text-[#0E0008]">
                        {'Learn'}
                      </h1>
                      <p className="text-[14px] text-lato text-[#575757]">
                        {'Explore courses on shecluded'}
                      </p>
                    </Box>
                  </MuiListItemButton>
                </MuiListItem>
                <MuiListItem className="p-0 m-0">
                  <MuiListItemButton
                    className="px-[20px] py-[20px]"
                    onClick={() => {
                      navigate('/directory');
                    }}
                  >
                    <MuiListItemIcon className="">
                      <Avatar className={'bg-[#EDFFF5]'}>
                        <MuiPortraitIcon className={'text-[#3EBA73]'} />
                      </Avatar>
                    </MuiListItemIcon>
                    <Box>
                      <h1 className="text-[16px] font-lato text-[#0E0008]">
                        {'Business Directory'}
                      </h1>
                      <p className="text-[14px] text-lato text-[#575757]">
                        {'Explore businesses and get insights from them'}
                      </p>
                    </Box>
                  </MuiListItemButton>
                </MuiListItem>
              </MuiList>
            </div>
          </Box>
        </MuiGrid>
      </MuiGrid>
    </div>
  );
}
