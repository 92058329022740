import { useState, useEffect } from "react";

import contexts from "../contexts";

function ComponentsState(props) {
  const inititalComponentsState = {
    loaderV1: {
      isOpen: true,
    },
    alertV1: {
      isOpen: false,
      message: "",
      severity: "info",
    },
    sidebarV1: {
      isOpen: true,
    },
  };

  const [componentsState, setComponentsState] = useState(
    inititalComponentsState
  );

  useEffect(() => {
    console.log("Components State===>", componentsState);
    return () => {};
  }, [componentsState]);

  return (
    <contexts.componentsContext.Provider
      value={[componentsState, setComponentsState]}
    >
      {props.children}
    </contexts.componentsContext.Provider>
  );
}

export default ComponentsState;
