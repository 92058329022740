import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RoutedTab } from "../../../../components/Directory";
import { Avatar, Button as MuiButton } from "@mui/material/";

import { WhatsApp, Instagram, Edit } from "@mui/icons-material";

import { sheCludedBanner } from "../../../../assets/images";

import { useCustomer as useCustomerState } from "../../../../hooks";

import services from "../../../../services";

import {
  facebookGrayIcon,
  twitterGrayIcon,
  linkedinGrayIcon,
  shareWhiteIcon,
} from "../../../../assets";

const Business = () => {
  const navigate = useNavigate();
  const [customerState, setCustomerState] = useCustomerState();

  useEffect(() => {
    services.accounts.customers
      .findOne("id", sessionStorage.getItem("customer"))
      .then((data) => {
        setCustomerState({ ...customerState, customer: data.data });

        if (data.data.directory.isCreated === false) {
          navigate("/directory/create");
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  }, []);

  console.log({ customerState });
  return (
    <div className="pt-[70px] px-2 md:px-6 font-dmSans">
      <RoutedTab />

      <div className="my-5">
        <h2 className="font-medium text-[20px] text-gray-9">
          Business information
        </h2>
      </div>

      <section className=" rounded-[10px] shadow-4xl">
        <article className="relative">
          {/* banner */}
          <label
            htmlFor="update_cover_image"
            className="relative h-[198px] w-[100%] bg-gray-500 overflow-hidden"
          >
            <img
              src={customerState.customer?.directory?.directory?.coverImage}
              alt="banner"
              className="w-full h-full object-cover"
            />
          </label>

          {/* logo */}
          <div className="absolute left-8 -bottom-10">
            <label
              className="w-20 h-20 rounded-full bg-gray-200  border-4 border-white
          shadow-3xl overflow-hidden relative"
              htmlFor="update_image"
            >
              <Avatar
                className="object-cover h-full w-full"
                src={customerState.customer?.directory?.directory?.image}
                alt="user's profile"
              />
            </label>
          </div>
        </article>

        <div className="mt-10 p-5">
          <h3 className="font-medium text-[20px] text-gray-9 capitalize ">
            {customerState?.customer?.firstName}{" "}
            {customerState?.customer?.lastName}
          </h3>

          <div className="mt-1 lg:flex items-center text-[16px]">
            <span className="text-gray-9 ">
              {" "}
              {`@${customerState?.customer?.directory?.directory?.username}`}
            </span>
            <span className="hidden w-1 h-1 lg:block rounded-full bg-gray-3 mx-1"></span>
            <span className="text-gray-5 block">
              {`https://directory.shecluded.com/${customerState?.customer?.directory?.directory?.username}`}
            </span>
          </div>

          <p className="w-full my-3 xl:w-1/2 text-[14px] font-medium text-gray-5 ">
            {customerState.customer?.directory?.directory?.story}
          </p>
          <div className="flex">
            <MuiButton className="normal-case flex items-center bg-primary w-fit text-white text-[16px] py-[6px] px-[14px] rounded">
              <img src={shareWhiteIcon} alt="share icon" />
              <span className="ml-1 md:ml-2">Share profile</span>
            </MuiButton>
            <MuiButton
              startIcon={<Edit />}
              className="ml-[10px] normal-case flex items-center bg-[black] w-fit text-white text-[16px] py-[6px] px-[14px] rounded"
              onClick={() => {
                navigate("/directory/update");
              }}
            >
              <span className="ml-1 md:ml-2">Update profile</span>
            </MuiButton>
          </div>
        </div>
      </section>

      <section className="my-5 p-5 border border-gray-3 rounded-[10px]">
        <h2 className="mb-5 font-medium text-[20px] text-gray-9">
          Personal information
        </h2>

        <article className="w-full xl:w-3/5">
          <div>
            <span className='text-[14px] font-medium text-gray-7 mb-1 block"'>
              Business Name
            </span>
            <div className="text-[16px] font-medium text-gray-5 border border-gray-3  px-[14px] py-[10px] rounded">
              {customerState.customer?.directory?.directory?.business.name}
            </div>
          </div>

          <div className="mt-3">
            <span className='text-[14px] font-medium text-gray-7 mb-1 block"'>
              About Business
            </span>
            <div className=" text-[16px] font-medium text-gray-5  border border-gray-3 px-[14px] py-[10px] rounded">
              {customerState.customer?.directory?.directory?.business?.story}
            </div>
          </div>
        </article>
      </section>

      <section className="border border-gray-3 rounded-[10px] p-5">
        <h2 className="mb-5 font-medium text-[20px] text-gray-9">
          Social Media
        </h2>

        <ul className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <li className="py-2 px-3 border border-gray-3  rounded flex items-center">
            <img src={facebookGrayIcon} alt="facebook icon" />
            <span className="ml-2 text-[16px] text-gray-7 font-medium">
              {
                customerState.customer?.directory?.directory?.business
                  ?.socialMedia?.facebook
              }
            </span>
          </li>

          <li className="py-2 px-3 border border-gray-3  rounded flex items-center">
            <img src={twitterGrayIcon} alt="facebook icon" />
            <span className="ml-2 text-[16px] text-gray-7 font-medium">
              {
                customerState.customer?.directory?.directory?.business
                  ?.socialMedia?.twitter
              }
            </span>
          </li>

          <li className="py-2 px-3 border border-gray-3  rounded flex items-center">
            <img src={linkedinGrayIcon} alt="facebook icon" />
            <span className="ml-2 text-[16px] text-gray-7 font-medium">
              {
                customerState.customer?.directory?.directory?.business
                  ?.socialMedia?.linkedin
              }
            </span>
          </li>

          <li className="py-2 px-3 border border-gray-3  rounded flex items-center">
            <WhatsApp />
            <span className="ml-2 text-[16px] text-gray-7 font-medium">
              {
                customerState.customer?.directory?.directory?.business
                  ?.socialMedia?.whatsapp
              }
            </span>
          </li>

          <li className="py-2 px-3 border border-gray-3  rounded flex items-center">
            <Instagram />
            <span className="ml-2 text-[16px] text-gray-7 font-medium">
              {
                customerState.customer?.directory?.directory?.business
                  ?.socialMedia?.instagram
              }
            </span>
          </li>
        </ul>
      </section>
      <input
        id="update_image"
        type="file"
        className="hidden"
        onChange={(event) => {
          const file = event.target.files[0];
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            const imagebase64String = reader.result;
            services.accounts.customers.directory
              .update(
                customerState.customer.directory.directory._id,
                "update",
                { image: imagebase64String }
              )
              .then(() => {
                setCustomerState(() => {
                  let newCustomerState = { ...customerState };
                  newCustomerState.customer.directory.directory.image =
                    imagebase64String;
                  return { ...newCustomerState };
                });
                alert("Image updated successfully");
              })
              .catch((error) => {
                alert(error.message);
              });
          };
        }}
      />
      <input
        id="update_cover_image"
        type="file"
        className="hidden"
        onChange={(event) => {
          const file = event.target.files[0];
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            const imagebase64String = reader.result;
            services.accounts.customers.directory
              .update(
                customerState.customer.directory.directory._id,
                "update",
                { coverImage: imagebase64String }
              )
              .then(() => {
                setCustomerState(() => {
                  let newCustomerState = { ...customerState };
                  newCustomerState.customer.directory.directory.coverImage =
                    imagebase64String;
                  return { ...newCustomerState };
                });
                alert("cover image updated successfully");
              })
              .catch((error) => {
                alert(error.message);
              });
          };
        }}
      />
    </div>
  );
};

export default Business;
