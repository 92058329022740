import { useContext } from "react";

import contexts from "../contexts";

function useCustomer() {
  const [customerState, setCustomerState] = useContext(
    contexts.customerContext
  );

  return [customerState, setCustomerState];
}

export default useCustomer;
