import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Avatar, Button as MuiButton } from "@mui/material/";

import services from "../../services";

import {
  useComponentsState,
  useCustomer as useCustomerState,
} from "../../hooks";

const PersonalInfo = ({ activestep, changeActive, formData, setFormData }) => {
  const navigate = useNavigate();
  const { alertV1 } = useComponentsState();
  const [customerState, setCustomerState] = useCustomerState();

  const [isCreating, setIsCreating] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    const fileSize = file.size;
    const maxSize = 2 * 1024 * 1024; // 5 MB

    if (fileSize > maxSize) {
      alert("You exceeded the upload limit of 2mb");

      return false;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      const base64String = reader.result;

      setFormData({ ...formData, image: base64String });
    };
    reader.onerror = (error) => {
      console.error(error);
    };
  };

  const handleClick = handleSubmit((data) => {
    setIsCreating(true);

    //   call endpoint to submit formData and
    services.accounts.customers.directory
      .create({
        ...formData,
        customer: sessionStorage.getItem('customer')
      })
      .then((data) => {
        alert(data.message);

        let newCustomerState = { ...customerState };
        newCustomerState.customer.directory.isCreated = true;
        newCustomerState.customer.directory.directory = {
          ...formData,
        };
        setCustomerState({ ...newCustomerState });
        setIsCreating(false);
        //push to directory profile
        navigate("/directory");
      })
      .catch((error) => {
        setIsCreating(false);
        alert(error.message);
        console.log(error);
        if (error?.message === "Username is alrady taken") {
        }
      });
  });

  return (
    <section className={activestep === 5 ? "block " : "hidden"}>
      <p className="text-[20px] lg:text-[30px] text-center font-semibold text-black-1 font-playfair">
        Fill in your personal details
      </p>

      <form className="max-w-[400px] mx-auto mt-6">
        <div className=" bg-white flex items-center cursor-pointer relative">
          <div
            className="w-20 h-20 rounded-full bg-gray-50 border-4 border-white
          shadow-3xl overflow-hidden relative"
          >
            <Avatar
              className="object-cover h-full w-full "
              src={formData.image}
              alt="user's profile"
            />
          </div>

          <div className="ml-4 text-primary text-[16px] font-medium">
            Upload photo
          </div>

          <input
            type="file"
            accept={".jpg, .png, .jpeg, .svg, .gif"}
            max-file-size="2048"
            onChange={handlePhotoChange}
            className="cursor-pointer  w-full h-full absolute top-0 left-0 opacity-0"
          />
        </div>

        <div className="w-full my-5">
          <label className="text-left text-[14px] font-medium text-gray-7 mb-1 block">
            Username
          </label>
          <input
            className="w-full text-[16px] border border-gray-3 focus:outline-0 focus:border-primary px-[14px] py-[10px] rounded"
            type="text"
            placeholder="Username"
            {...register("username", { required: true })}
            onChange={(event) => {
              setFormData({ ...formData, username: event.target.value });
            }}
          />
        </div>

        <div className="w-full">
          <label className="text-left text-[14px] font-medium text-gray-7 mb-1 block">
            Tell us about yourself
          </label>
          <textarea
            className="w-full text-[16px] border border-gray-3 focus:outline-0 focus:border-primary px-[14px] py-[10px] rounded"
            type="text"
            placeholder="Enter a description"
            rows="5"
            {...register("info", { required: true })}
            onChange={(event) => {
              setFormData({ ...formData, story: event.target.value });
            }}
          ></textarea>
          {errors.info && (
            <div className="text-red-400"> Business name is required </div>
          )}
        </div>

        <div className="text-center mt-5">
          <MuiButton
            onClick={handleClick}
            className="bg-primary w-full text-white capitalize text-[16px] py-[10px] px-[40px] rounded"
          >
            {isCreating ? "creating..." : "next"}
          </MuiButton>
        </div>
      </form>
    </section>
  );
};

export default PersonalInfo;
