import { useNavigate, Routes, Route, Navigate } from "react-router-dom";

import { Verify } from "./pages";

export default function EmailAddress() {
  return (
    <Routes>
      <Route path={"/verify"} element={<Verify />} />
    </Routes>
  );
}
