import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import logo from "../../assets/logo.svg";

import services from "../../services";
import {
  useAlert,
  useCustomer as useCustomerState,
  useComponentsState,
} from "../../hooks";

export default function SignUp() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [customerState, setCustomerState] = useCustomerState();

  const { componentsState, setComponentsState } = useComponentsState();
  const [formData, setFormData] = useState({});
  const _alert = useAlert();
  const [pageReadyState, setPageReadyState] = useState(0);

  useEffect(() => {
    if (formData.emailAddress) {
      searchParams.set("email_address", formData.emailAddress);
      setSearchParams(searchParams);
    }
  }, [formData]);

  useEffect(() => {
    if (pageReadyState === 1) {
      setTimeout(() => {
        setComponentsState({ ...componentsState, loaderV1: { isOpen: false } });
      }, 1000);
    }
  }, [pageReadyState]);

  useEffect(() => {
    setTimeout(() => {
      setPageReadyState(pageReadyState + 1);
    }, 1000);

    return () => {
      setComponentsState({ ...componentsState, loaderV1: { isOpen: true } });
    };
  }, []);
  return (
    <div className="flex w-[100%] h-[900px]">
      <div className="hidden md:block w-[50%] h-[100%] bg-[#0f0108]">
        <div className="flex items-center ml-[10%] mt-[40px] mb-5">
          <h1 className="text-[#c3b5bd]">shecluded</h1>
          <img
            className="w-[20.32px] h-[40.92px] pb-[7px] ml-[-2px]"
            src={logo}
            alt=""
          />
        </div>
        <div className="w-[100%] flex flex-col items-center space-y-[40px] mt-4">
          <div className="text-center text-[white] space-x-2 text-[32px] font-lato">
            <span>Powering</span>
            <span className="text-[#ED1F8E] font-playfair">Finance</span>
            <span>For Women</span>
          </div>
          <div className="">{/*<CarouselImages />*/}</div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center w-[100%] md:w-[50%] h-[100%] bg-[white]">
        <div className="flex flex-col items-center w-[60%] space-y-[50px]">
          <div>
            <h1 className="text-[24px] md:text-[32px] font-semibold">
              Create Account
            </h1>
          </div>
          <form className="w-[100%]">
            <div className="flex flex-col w-[100%] space-y-5">
              <div className="space-y-2">
                <label>First name</label>
                <br />
                <input
                  className="w-[100%] border border-[#C2C3C8] h-[52px] rounded-[8px] pl-5"
                  type="text"
                  id="firstname"
                  name="firstname"
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      firstName: event.target.value,
                    });
                    setCustomerState((customerState) => {
                      return {
                        ...customerState,
                        customer: {
                          ...customerState.customer,
                          firstName: event.target.value,
                        },
                      };
                    });
                  }}
                />
              </div>
              <div className="space-y-2">
                <label>Last Name</label>
                <br />
                <input
                  className="w-[100%] border border-[#C2C3C8] h-[52px] rounded-[8px] pl-5"
                  type="text"
                  id="lastname"
                  name="lastname"
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      lastName: event.target.value,
                    });
                    setCustomerState((customerState) => {
                      return {
                        ...customerState,
                        customer: {
                          ...customerState.customer,
                          lastName: event.target.value,
                        },
                      };
                    });
                  }}
                />
              </div>
              <div className="space-y-2">
                <label>Email</label>
                <br />
                <input
                  className="w-[100%] border border-[#C2C3C8] h-[52px] rounded-[8px] pl-5"
                  type="email"
                  id="email"
                  name="email"
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      emailAddress: event.target.value,
                    });
                    setCustomerState((customerState) => {
                      return {
                        ...customerState,
                        customer: {
                          ...customerState.customer,
                          emailAddress: event.target.value,
                        },
                      };
                    });
                  }}
                />
              </div>
              <div className="space-y-2">
                <label>Password</label>
                <br />
                <input
                  className="w-[100%] border border-[#C2C3C8] h-[52px] rounded-[8px] pl-5"
                  type="password"
                  id="password"
                  name="password"
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      password: event.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </form>
          <button
            onClick={() => {
              services.accounts.auth.signUp
                .customer(formData)
                .then((data) => {
                  alert(data.message);
                  sessionStorage.setItem("customer", data.data._id);
                  setTimeout(() => {
                    navigate("/email-address/verify" + window.location.search);
                  }, 1000);
                })
                .catch((error) => {
                  alert(error.message);
                });
            }}
            className="w-[100%] h-[52px] text-center bg-[#ED1F8E] rounded-[8px] text-[white]"
          >
            Create Account
          </button>
          <div>
            <p>
              Already have an account?{" "}
              <span className="text-[#ED1F8E]">
                <Link to="/signin">Sign In</Link>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
