import { useContext } from "react";

import contexts from "../contexts";

function useAlert() {
  const [alertState, setAlertState] = useContext(contexts.alertContext);
  return {
    alertState,
    handleOpen: function (message) {
      setAlertState({ isOpen: true, message: message });
    },
    handleClose: function () {
      setAlertState({ isOpen: false, message: "" });
    },
  };
}

export default useAlert;
