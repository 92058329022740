import React, { useState } from 'react';
import HeaderV1 from '../../HeaderV1';
import SidebarV1 from '../../SidebarV1';

const DashDirectory = ({ children }) => {
  return (
    <div className="flex overflow-x-hidden">
      <SidebarV1 selectedIndex={1} />

      <div className="flex-1 bg-[#fff]">
        <HeaderV1 />

        <div>{children}</div>
      </div>
    </div>
  );
};

export default DashDirectory;
