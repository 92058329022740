import { Delete } from "@mui/icons-material";
import services from "../../../../../services";
import {
  Grid as MuiGrid,
  IconButton as MuiIconButton,
  Fade as MuiFade,
} from "@mui/material";
import { useState } from "react";
import { useCustomer as useCustomerState } from "../../../../../hooks";

export function ProductView(props) {
  const [showOverlay, setShowOverlay] = useState(false);
  const [customerState, setCustomerState] = useCustomerState();
  return (
    <MuiGrid item xs={6} lg={4} className="h-[200px]">
      <div
        className="h-[100%] relative"
        onMouseEnter={() => {
          setShowOverlay(true);
        }}
        onMouseLeave={() => {
          setShowOverlay(false);
        }}
      >
        <img
          alt="product"
          src={props.item.images[0]}
          className="h-[100%] w-[100%] object-cover"
        />
        <MuiFade in={showOverlay} mountOnEnter unmountOnExit>
          <div className="absolute flex justify-content-center align-items-center top-0 bottom-0 right-0 left-0 bg-[black] opacity-75">
            <MuiIconButton
              className="p-[15px] border-1 border-[white]"
              onClick={() => {
                services.accounts.customers.directory.products
                  .delete(props.formData._id, props.item._id)
                  .then(() => {
                    services.accounts.customers
                      .findOne("id", sessionStorage.getItem("customer"))
                      .then((data) => {
                        setCustomerState({
                          ...customerState,
                          customer: data.data,
                        });
                        
                      })
                      .catch((error) => {
                        alert(error.message);
                      });
                    alert("Product deleted successfully!");
                  })
                  .catch((error) => {
                    alert(error.message);
                  });
              }}
            >
              <Delete className="text-[white]" />
            </MuiIconButton>
          </div>
        </MuiFade>
      </div>
    </MuiGrid>
  );
}
