import React from 'react';
import { RoutedTab } from '../../../../components/Directory';

const Messages = () => {
  return (
    <div className="py-3 px-2 md:px-6 font-dmSans ">
      <RoutedTab />

      <div className="h-80 flex items-center justify-center">COMING SOON</div>
    </div>
  );
};

export default Messages;
