import { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import {
  useComponentsState,
  useCustomer as useCustomerState,
} from "../../../../hooks";
import {
  Box,
  Typography,
  Card,
  Button,
  Grid as MuiGrid,
  Card as MuiCard,
  CardContent as MuiCardContent,
  CardActions as MuiCardActions,
  Button as MuiButton,
  Typography as MuiTypography,
  LinearProgress as MuiLinearProgress,
  Box as MuiBox,
} from "@mui/material/";
import { SidebarV1, HeaderV1 } from "../../../../components";

import EastIcon from "@mui/icons-material/East";

import "./style.css";
import image101 from "../../../../assets/images/image101.png";
import image102 from "../../../../assets/images/image102.svg";
import image103 from "../../../../assets/images/image103.svg";

export default function Root() {
  const navigate = useNavigate();
  const { componentsState, setComponentsState, loaderV1 } =
    useComponentsState();
  const [customerState, setCustomerState] = useCustomerState();
  const [pageReadyState, setPageReadyState] = useState(0);

  useEffect(() => {
    if (pageReadyState === 1) {
      setTimeout(() => {
        loaderV1.close();
      }, 500);
    }
  }, [pageReadyState]);

  useEffect(() => {
    // if (customerState.isSignedIn === false) {
    //   navigate("/signin");
    // } else {
    //   setTimeout(() => {
    //     setPageReadyState(pageReadyState + 1);
    //   }, 500);
    // }

    setTimeout(() => {
      setPageReadyState(pageReadyState + 1);
    }, 500);

    return () => {
      loaderV1.open();
    };
  }, []);

  return (
    pageReadyState === 1 && (
      <MuiGrid>
        <HeaderV1 />
        <MuiGrid container={true} wrap={"nowrap"}>
          <MuiGrid item={true}>
            <SidebarV1 selectedIndex={2} />
          </MuiGrid>
          <MuiGrid item={true} className={"flex-grow-1 bg-light py-5"}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginBottom: "40px",
              }}
            >
              <Box sx={{ width: "95%" }}>
                <Box>
                  <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
                    {`Loans`}
                  </Typography>
                  <Typography sx={{ color: "gray" }}>
                    {"View your loan history here"}
                  </Typography>
                </Box>
                <MuiGrid container={true} alignItems={"stretch"}>
                  {[
                    {
                      title: "Apply for loan here",
                      backgroundColor: "bg-[#0E0008]",
                      backgroundImage: image101,
                      button: {
                        text: "apply",
                        onClick: () => {
                          navigate("/loans/apply");
                        },
                      },
                    },
                    {
                      title: "Check eligibility",
                      backgroundColor: "bg-[#FF7733]",
                      backgroundImage: image102,
                      button: {
                        text: "check",
                        onClick: () => {
                          alert("credit eligibilty feature coming soon");
                        },
                      },
                    },

                    {
                      title: "Loan Calculator",
                      backgroundColor: "bg-[#3EBA73]",
                      backgroundImage: image103,
                      button: {
                        text: "open",
                        onClick: () => {
                          navigate("/loans/calculator");
                        },
                      },
                    },
                  ].map((item, index) => {
                    return (
                      <MuiGrid
                        lg={4}
                        md={4}
                        sm={6}
                        xs={12}
                        className={"p-3 ps-0"}
                        item={true}
                        key={index}
                      >
                        <MuiGrid
                          elevation={0}
                          className={`p-[15px] rounded-3 h-[100%] ${item.backgroundColor}`}
                        >
                          <MuiGrid
                            container={true}
                            wrap={"nowrap"}
                            className={"h-[100%]"}
                          >
                            <MuiGrid className="justify-content-between d-flex flex-column">
                              <MuiGrid className={`flex-grow-1`}>
                                <Typography
                                  variant="h6"
                                  component="div"
                                  noWrap={true}
                                  className={"text-white"}
                                >
                                  {item.title}
                                </Typography>
                              </MuiGrid>
                              <MuiGrid className={""}>
                                <Button
                                  variant={"contained"}
                                  disableElevation={true}
                                  className={"bg-white rounded-0"}
                                  onClick={item.button.onClick}
                                >
                                  <MuiTypography
                                    className={
                                      "text-dark text-capitalize text-[13px]"
                                    }
                                  >
                                    {item.button.text}
                                  </MuiTypography>
                                </Button>
                              </MuiGrid>
                            </MuiGrid>
                            <MuiGrid
                              className={`flex-grow-1 d-flex align-items-center`}
                            >
                              <img
                                className="img img-fluid"
                                alt={"overlay"}
                                src={item.backgroundImage}
                              />
                            </MuiGrid>
                          </MuiGrid>
                        </MuiGrid>
                      </MuiGrid>
                    );
                  })}
                </MuiGrid>

                <MuiGrid container={true} className={"w-[100%]"} spacing={2}>
                  <MuiGrid item={true} xs={12} sm={12} md={6}>
                    <Card className="p-[20px] bg-[#FCE9F4]">
                      <h1 className="text-[16px] font-lato font-bold text-[#0E0008]">
                        Verify your identity
                      </h1>
                      <div className="flex space-x-1 mt-2 text-[#575757]">
                        <p>
                          Get access to loans and directory when you complete
                          your profile
                        </p>
                        <EastIcon />
                      </div>
                    </Card>
                  </MuiGrid>
                  <MuiGrid item={true} xs={12} sm={12} md={6}>
                    <Card className="w-[100%] p-[20px] bg-[#FBF5E5]">
                      <h1 className="text-[16px] font-lato font-bold text-[#0E0008]">
                        Complete your profile
                      </h1>
                      <div className="flex space-x-1 mt-2 text-[#575757]">
                        <p>
                          Get access to loans and directory when you complete
                          your profile
                        </p>
                        <EastIcon />
                      </div>
                    </Card>
                  </MuiGrid>
                </MuiGrid>
                <div className="w-[100%] mt-[30px] space-y-4">
                  <h1 className="text-[16px] font-semibold">My Loans</h1>
                  <Box sx={{ width: "100%" }}>
                    {customerState.loans.map((item, index) => {
                      if (item.status === "ACTIVE") {
                        return (
                          <MuiGrid
                            className={"px-3 bg-white my-3 py-4 px-3"}
                            container={true}
                            justifyContent={"space-between"}
                            key={index}
                          >
                            <MuiGrid className="flex items-center h-[100%] space-x-5">
                              <Box>
                                <h1 className="text-[15px] text-[#0E0008]">
                                  {item.title}
                                </h1>
                              </Box>
                            </MuiGrid>
                            <MuiGrid
                              className={"h-100 d-flex align-items-center"}
                            >
                              <MuiTypography>{item.amount}</MuiTypography>
                            </MuiGrid>
                            <MuiGrid className={"w-100 py-4"}>
                              <MuiLinearProgress
                                variant="determinate"
                                value={80}
                              />
                            </MuiGrid>
                          </MuiGrid>
                        );
                      }

                      return (
                        <MuiGrid
                          className={"px-3 bg-white my-3 py-4 px-3"}
                          container={true}
                          justifyContent={"space-between"}
                          key={index}
                        >
                          <MuiGrid className="flex items-center h-[100%] space-x-5">
                            <Box>
                              <h1 className="text-[15px] text-[#0E0008]">
                                {item.title}
                              </h1>

                              {item.status === "SUBMITTED" && (
                                <p className="text-primary text-[12px]">
                                  Your loan request has been submitted
                                </p>
                              )}
                              {item.status === "APPROVED" && (
                                <p className="text-success text-[12px]">
                                  Your loan request has been approved
                                </p>
                              )}
                              {item.status === "REJECTED" && (
                                <p className="text-danger text-[12px]">
                                  Your loan request has been rejected
                                </p>
                              )}
                            </Box>
                          </MuiGrid>
                          <MuiGrid
                            className={"h-100 d-flex align-items-center"}
                          >
                            {item.status === "SUBMITTED" && (
                              <Fragment>
                                <MuiButton
                                  className={"Y23232 p-2 me-1"}
                                  variant="contained"
                                  disableElevation
                                  disableRipple
                                >
                                  Cancel Request
                                </MuiButton>
                                <MuiButton
                                  className={"G4343 p-2"}
                                  variant="contained"
                                  disableElevation
                                  disableRipple
                                  onClick={() => {
                                    navigate("/loans/view?mode=single");
                                  }}
                                >
                                  View Details
                                </MuiButton>
                              </Fragment>
                            )}
                            {item.status === "APPROVED" && (
                              <Fragment>
                                <MuiButton
                                  className={"U232323 p-2 me-1"}
                                  variant="contained"
                                  disableElevation
                                  disableRipple
                                >
                                  View Offer
                                </MuiButton>
                                <MuiButton
                                  className={"G4343 p-2"}
                                  variant="contained"
                                  disableElevation
                                  disableRipple
                                  onClick={() => {
                                    navigate("/loans/view?mode=single");
                                  }}
                                >
                                  View Details
                                </MuiButton>
                              </Fragment>
                            )}
                            {item.status === "REJECTED" && (
                              <Fragment>
                                <MuiButton
                                  className={"F32323 p-2 me-1"}
                                  variant={"contained"}
                                  disableElevation
                                  disableRipple
                                >
                                  View Reason
                                </MuiButton>
                                <MuiButton
                                  className={"G4343 p-2"}
                                  variant="contained"
                                  disableElevation
                                  disableRipple
                                  onClick={() => {
                                    navigate("/loans/view?mode=single");
                                  }}
                                >
                                  View Details
                                </MuiButton>
                              </Fragment>
                            )}
                          </MuiGrid>
                        </MuiGrid>
                      );
                    })}
                    <MuiButton className="w-100 fw-bold">
                      {"Show full loan history"}
                    </MuiButton>
                  </Box>
                </div>
              </Box>
            </Box>
          </MuiGrid>
        </MuiGrid>
      </MuiGrid>
    )
  );
}
