import React from "react";
import { Link, useLocation } from "react-router-dom";

const RoutedTab = () => {
  const { pathname } = useLocation();

  return (
    <ul className="flex items-center w-[100%]">
      <Link to="/directory">
        <li
          className={`${
            pathname === "/directory" ? "bg-gray-50 py-1 px-3  rounded" : ""
          } text-[14px] md:text-[16px] text-gray-5 font-medium cursor-pointer mr-4 `}
        >
          Business Information
        </li>
      </Link>

      <Link to="/directory/product">
        <li
          className={`${
            pathname.includes("/directory/product")
              ? "bg-gray-50 py-1 px-3  rounded"
              : ""
          } text-[14px] md:text-[16px] text-gray-5 font-medium cursor-pointer mr-4 `}
        >
          Product
        </li>
      </Link>

      <Link to="/directory/messages">
        <li
          className={`${
            pathname === "/directory/messages"
              ? "bg-gray-50 py-1 px-3  rounded"
              : ""
          } text-[14px] md:text-[16px] text-gray-5 font-medium cursor-pointer mr-4 `}
        >
          Messages
        </li>
      </Link>
    </ul>
  );
};

export default RoutedTab;
