import { useState, useEffect } from "react";

import contexts from "../contexts";

function CustomerState(props) {
  const initialCustomerState = {
    isSignedIn: false,
    accessToken: null,
    refreshToken: null,
    customer: { directory: { isCreated: false, products: [] } },
    loans: [],
  };

  const cache = JSON.parse(sessionStorage.getItem("wddddededed"));
  if (cache === null) {
    sessionStorage.setItem("wddddededed", JSON.stringify(initialCustomerState));
  }

  const [customerState, setCustomerState] = useState(
    cache || initialCustomerState
  );

  useEffect(() => {
    sessionStorage.setItem("wddddededed", JSON.stringify(customerState));
  }, [customerState]);

  useEffect(() => {
    console.log("CUSTOMER STATE ===>", customerState);
  }, [customerState]);
  return (
    <contexts.customerContext.Provider
      value={[customerState, setCustomerState]}
    >
      {props.children}
    </contexts.customerContext.Provider>
  );
}

export default CustomerState;
