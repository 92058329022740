import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Slide,
  Button as MuiButton,
} from "@mui/material";

import {
  useAlert,
  useCustomer as useCustomerState,
  useComponentsState,
} from "../../hooks";
import services from "../../services";


import checked from "../../assets/images/check.svg";
import emoji from "../../assets/emoji.svg";
import facebook from "../../assets/facebook.svg";
import linkedn from "../../assets/linkedn.svg";
import twitter from "../../assets/twitter.svg";
import instagram from "../../assets/instagram.svg";

import "./style.css";
export default function Onboard() {
  const navigate = useNavigate();
  const { componentsState, setComponentsState } = useComponentsState();
  const [customer, setCustomer] = useCustomerState();
  const [activeStep, setActiveStep] = useState(1);
  const [stepsCompleted, setStepsCompleted] = useState(false);
  const [formData, setFormData] = useState({});
  const [pageReadyState, setPageReadyState] = useState(0);
  const _alert = useAlert();

  useEffect(() => {
    console.log("formData", formData);
  }, [formData]);

  useEffect(() => {
    if (pageReadyState === 1) {
      setTimeout(() => {
        setComponentsState({ ...componentsState, loaderV1: { isOpen: false } });
      }, 1000);
    }
  }, [pageReadyState]);

  useEffect(() => {
    setTimeout(() => {
      setPageReadyState(pageReadyState + 1);
    }, 1000);

    return () => {
      setComponentsState({ ...componentsState, loaderV1: { isOpen: true } });
    };
  }, []);
  return (
    pageReadyState === 1 && (
      <div className={"absolute top-0 bottom-0 right-0 left-0"}>
        <div className={"container mx-auto"}>
          <Slide
            exit={false}
            in={stepsCompleted === false}
            mountOnEnter
            unmountOnExit
          >
            <div className={"container"}>
              <Stepper
                activeStep={activeStep}
                connector={<StepConnector className={"A113 rounded-3 my-3"} />}
              >
                {[...Array(8)].map((item, index) => (
                  <Step key={index}>
                    <StepLabel
                      StepIconComponent={() => {
                        return <span></span>;
                      }}
                    ></StepLabel>
                  </Step>
                ))}
              </Stepper>
              <div className={"w-100"}>
                <Slide
                  in={activeStep === 1}
                  direction={"left"}
                  exit={false}
                  mountOnEnter
                  unmountOnExit
                >
                  <div className="flex flex-col items-center mt-[70px] ">
                    <h1 className="flex flex-col space-y-2 text-center text-[24px] md:text-[34px] font-lato font-semibold">
                      <span>{`Hello, ${customer.customer.firstName} 👋🏼,`}</span>
                      <span>Let's get some information about you</span>
                    </h1>
                    <div className="flex flex-col items-center w-[100%] md:w-[40%] mt-[30px] space-y-4">
                      <h1 className="text-[16px] md:text-[30px]">
                        Where do you Live?
                      </h1>
                      <form className="w-[100%] space-y-[25px]">
                        <input
                          className="w-[100%] border border-[#C2C3C8] h-[52px] rounded-[8px] pl-5"
                          type="text"
                          placeholder="enter address"
                          onChange={(event) => {
                            setFormData({
                              ...formData,
                              homeAddress: event.target.value,
                            });
                          }}
                        />
                        <button
                          type="submit"
                          onClick={() => {
                            setActiveStep(2);
                          }}
                          className="w-[100%] h-[52px] text-center bg-[#ED1F8E] rounded-[8px] text-[white]"
                        >
                          Proceed
                        </button>
                      </form>
                    </div>
                  </div>
                </Slide>
                <Slide
                  in={activeStep === 2}
                  direction={"left"}
                  exit={false}
                  mountOnEnter
                  unmountOnExit
                >
                  <div className="flex flex-col items-center mt-3 ">
                    <h1 className="text-center text-[24px] md:text-[34px] font-lato font-medium mb-[30px]">
                      Let's get some more
                    </h1>
                    <div className="flex flex-col items-center w-[100%] md:w-[40%] mt-[30px] space-y-4">
                      <h1 className="text-center text-[16px] md:text-[30px]">
                        How old are you?
                      </h1>
                      <form className="w-[100%] space-y-[25px]">
                        <input
                          className="w-[100%] border border-[#C2C3C8] h-[52px] rounded-[8px] pl-5"
                          type="text"
                          id="address"
                          name="address"
                          placeholder="DD/MM/YYYY"
                          onChange={(event) => {
                            setFormData({
                              ...formData,
                              dateOfBirth: event.target.value,
                            });
                          }}
                        />
                        <button
                          onClick={() => {
                            setActiveStep(3);
                          }}
                          className="w-[100%] h-[52px] text-center bg-[#ED1F8E] rounded-[8px] text-[white]"
                        >
                          Proceed
                        </button>
                      </form>
                    </div>
                  </div>
                </Slide>
                <Slide
                  in={activeStep === 3}
                  direction={"left"}
                  exit={false}
                  mountOnEnter
                  unmountOnExit
                >
                  <div className="flex flex-col items-center mt-3 ">
                    <h1 className="text-center text-[24px] md:text-[34px] font-lato font-medium mb-[30px]">
                      Tell us about your love life ❤️
                    </h1>
                    <div className="flex flex-col items-center w-[100%] md:w-[40%] mt-[30px] space-y-4">
                      <h1 className="text-center text-[16px] md:text-[30px]">
                        Marital Status
                      </h1>
                      <form className="w-[100%] space-y-[25px]">
                        <select
                          onClick={(event) => {
                            setFormData({
                              ...formData,
                              maritalStatus: event.target.value,
                            });
                          }}
                          className="block appearance-none w-full h-[52px] bg-white border border-gray-400 hover:border-gray-500 hover:bg-[red] px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:text-[gray] focus:shadow-outline"
                        >
                          <option value="">Select an option</option>
                          <option value="single">Single</option>
                          <option value="divorced">Divorced</option>
                          <option value="engaged">Engaged</option>
                          <option value="married">Married</option>
                        </select>
                        <button
                          onClick={() => {
                            setActiveStep(4);
                          }}
                          className="w-[100%] h-[52px] text-center bg-[#ED1F8E] rounded-[8px] text-[white]"
                        >
                          Proceed
                        </button>
                      </form>
                    </div>
                  </div>
                </Slide>
                <Slide
                  in={activeStep === 4}
                  direction={"left"}
                  exit={false}
                  mountOnEnter
                  unmountOnExit
                >
                  <div className="flex flex-col items-center mt-3 ">
                    <h1 className="text-center text-[24px] md:text-[34px] font-lato font-medium mb-[30px]">
                      Have any kids? Let's know how many?
                    </h1>
                    <div className="flex flex-col items-center w-[100%] md:w-[40%] mt-[30px] space-y-4">
                      <h1 className="text-[16px] md:text-[30px]">Kids?</h1>
                      <form className="w-[100%] space-y-[25px]">
                        <input
                          className="w-[100%] border border-[#C2C3C8] h-[52px] rounded-[8px] pl-5"
                          type="number"
                          onChange={(event) => {
                            setFormData({
                              ...formData,
                              numberOfChildren: parseInt(event.target.value),
                            });
                          }}
                          placeholder="enter 0 for none"
                        />
                        <button
                          onClick={() => {
                            setActiveStep(5);
                          }}
                          type="submit"
                          className="w-[100%] h-[52px] text-center bg-[#ED1F8E] rounded-[8px] text-[white]"
                        >
                          Proceed
                        </button>
                      </form>
                    </div>
                  </div>
                </Slide>
                <Slide
                  in={activeStep === 5}
                  direction={"left"}
                  exit={false}
                  mountOnEnter
                  unmountOnExit
                >
                  <div className="flex flex-col items-center mt-3 ">
                    <h1 className="text-center text-[24px] md:text-[34px] font-lato font-medium mb-[30px]">
                      Tell about how you studied 📚
                    </h1>
                    <div className="flex flex-col items-center w-[100%] md:w-[40%] mt-[30px] space-y-4">
                      <h1 className="text-center text-[16px] md:text-[30px]">
                        Highest Education Qualification
                      </h1>
                      <form className="w-[100%] space-y-[25px]">
                        <select
                          onChange={(event) => {
                            setFormData({
                              ...formData,
                              highestEducationalQualification:
                                event.target.value,
                            });
                          }}
                          className="block appearance-none w-full h-[52px] bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:text-[gray] focus:shadow-outline"
                        >
                          <option value="">Select</option>
                          <option value="SSCE">SSCE</option>
                          <option value="ND">National Diploma(ND)</option>
                          <option value="HND">
                            Higher National Diploma(HND)
                          </option>
                          <option value="BSC">Bachelor's Degree</option>
                          <option value="PhD">PhD.</option>
                          <option value="Prof">Prof.</option>
                        </select>
                        <button
                          onClick={() => {
                            setActiveStep(6);
                          }}
                          className="w-[100%] h-[52px] text-center bg-[#ED1F8E] rounded-[8px] text-[white]"
                        >
                          Proceed
                        </button>
                      </form>
                    </div>
                  </div>
                </Slide>
                <Slide
                  in={activeStep === 6}
                  direction={"left"}
                  exit={false}
                  mountOnEnter
                  unmountOnExit
                >
                  <div className="flex flex-col items-center mt-3 ">
                    <div className="text-center font-lato">
                      <div className="flex space-x-2">
                        <h1 className="text-center text-[24px] md:text-[34px] font-medium ">
                          Are you on social media? Tell us
                        </h1>
                        <img src={emoji} alt="" />
                      </div>
                      <p className="text-center text-[#575757]">
                        Tell us the ones you are on and leave the rest
                      </p>
                    </div>
                    <div className="flex flex-col items-center w-[100%] md:w-[60%] mt-[30px] space-y-10">
                      <form className="w-[100%] md:space-y-[60px] mt-4">
                        <div className="flex w-[100%] flex-col md:flex-row md:space-x-2">
                          <div className="relative w-[100%] md:w-[50%] ">
                            <label className="absolute mt-[-30px]">
                              Facebook
                            </label>
                            <img
                              src={facebook}
                              alt="wewe"
                              className="absolute inset-y-3 left-3 h-[] w-[]"
                            />
                            <input
                              className="w-[100%] pl-10 pr-4 py-2 rounded-lg border-2 border-[#C2C3C8] focus:border-blue-500 focus:outline-none focus:shadow-outline-blue"
                              type="text"
                              name="facebook"
                              placeholder="https://facebook.com/"
                            />
                          </div>
                          <div className="relative w-[100%]  mt-[30px] md:mt-0 md:w-[50%]">
                            <label className="absolute mt-[-30px]">
                              LinkedIn
                            </label>
                            <img
                              src={linkedn}
                              alt="esdsss"
                              className="absolute inset-y-3 left-3 h-[] w-[]"
                            />
                            <input
                              className="w-[100%] pl-10 pr-4 py-2 rounded-lg border-2 border-[#C2C3C8] focus:border-blue-500 focus:outline-none focus:shadow-outline-blue"
                              type="text"
                              name="linkedn"
                              placeholder="https://linkedn.com/in"
                            />
                          </div>
                        </div>
                        <div className="flex flex-col md:flex-row md:space-x-2">
                          <div className="relative w-[100%] mt-[30px] md:mt-0 md:w-[50%]">
                            <label className="absolute mt-[-30px]">
                              Twitter
                            </label>
                            <img
                              src={twitter}
                              alt="ewe"
                              className="absolute inset-y-3 left-3 "
                            />
                            <input
                              className="w-[100%] pl-10 pr-4 py-2 rounded-lg border-2 border-[#C2C3C8] focus:border-blue-500 focus:outline-none focus:shadow-outline-blue"
                              type="text"
                              name="twitter"
                              placeholder="@username"
                            />
                          </div>
                          <div className="relative w-[100%] mt-[30px] md:mt-0 md:w-[50%]">
                            <label className="absolute mt-[-30px]">
                              Instagram
                            </label>
                            <img
                              src={instagram}
                              alt="3ewewe"
                              className="absolute inset-y-3 left-3 h-[] w-[]"
                            />
                            <input
                              className="w-[100%] pl-10 pr-4 py-2 rounded-lg border-2 border-[#C2C3C8] focus:border-blue-500 focus:outline-none focus:shadow-outline-blue"
                              type="text"
                              name="instagram"
                              placeholder="@username"
                            />
                          </div>
                        </div>
                      </form>
                      <button
                        type="submit"
                        onClick={() => {
                          setStepsCompleted(true);
                        }}
                        className="w-[100%] md:w-[454px] h-[52px] text-center bg-[#ED1F8E] rounded-[8px] text-[white]"
                      >
                        Proceed
                      </button>
                    </div>
                  </div>
                </Slide>
              </div>
            </div>
          </Slide>
          <Slide in={stepsCompleted === true} mountOnEnter unmountOnExit>
            <div className="flex flex-col items-center mb-5">
              <div className="flex justify-center w-[1201px] bg-[white] h-[540px]">
                <div className="flex flex-col items-center space-y-5 mt-[6rem]">
                  <div>
                    <img src={checked} alt="" />
                  </div>
                  <div className="text-center font-lato space-y-2">
                    <h1 className="text-center text-[32px] font-semibold">
                      Way to go sis. that was good
                    </h1>
                    <p>Welcome to shecluded</p>
                  </div>
                  <MuiButton
                    onClick={() => {
                      navigate("/customer-dashboard");
                    }}
                    className="w-[454px] h-[52px] flex justify-center items-center bg-[#ED1F8E] rounded-[8px] text-[white]"
                  >
                    Proceed
                  </MuiButton>
                </div>
              </div>
            </div>
          </Slide>
        </div>
      </div>
    )
  );
}
