import React, { useState } from "react";
import { set, useForm } from "react-hook-form";
import { Button as MuiButton } from "@mui/material/";

const SocialMedia = ({ activestep, changeActive, formData, setFormData }) => {
  const { register, handleSubmit } = useForm();
  const [submitProgress, setSubmitProgress] = useState(false);

  const handleClick = handleSubmit((data) => {
    changeActive(5, 4);
  });

  return (
    <section className={activestep === 4 ? "block " : "hidden"}>
      <p className="text-[20px] lg:text-[30px] text-center font-semibold text-black-1 font-playfair">
        Kindly tell us your business social media handles
      </p>

      <form className="max-w-[400px] mx-auto mt-6">
        <div className="w-full">
          <label className="text-left text-[14px] font-medium text-gray-7 mb-1 block">
            Instagram
          </label>
          <input
            className="w-full border border-gray-3 focus:outline-0 focus:border-primary text-[16px] px-[14px] py-[10px] rounded"
            type="text"
            placeholder="Instagram handle"
            {...register("instagram")}
            onChange={(event) => {
              let newFormData = { ...formData };
              newFormData.business.socialMedia.instagram = event.target.value;
              setFormData({ ...newFormData });
            }}
          />
        </div>

        <div className="w-full my-4">
          <label className="text-left text-[14px] font-medium text-gray-7 mb-1 block">
            Linkedin
          </label>
          <input
            className="w-full border border-gray-3 focus:outline-0 focus:border-primary text-[16px] px-[14px] py-[10px] rounded"
            type="text"
            placeholder="Linkedin profile"
            {...register("linkedin")}
            onChange={(event) => {
              let newFormData = { ...formData };
              newFormData.business.socialMedia.linkedin = event.target.value;
              setFormData({ ...newFormData });
            }}
          />
        </div>

        <div className="w-full">
          <label className="text-left text-[14px] font-medium text-gray-7 mb-1 block">
            Twitter
          </label>
          <input
            className="w-full border border-gray-3 focus:outline-0 focus:border-primary text-[16px] px-[14px] py-[10px] rounded"
            type="text"
            placeholder="Twitter username"
            {...register("twitter")}
            onChange={(event) => {
              let newFormData = { ...formData };
              newFormData.business.socialMedia.twitter = event.target.value;
              setFormData({ ...newFormData });
            }}
          />
        </div>

        <div className="w-full mt-4">
          <label className="text-left text-[14px] font-medium text-gray-7 mb-1 block">
            Whatsapp
          </label>
          <input
            className="w-full border border-gray-3 focus:outline-0 focus:border-primary text-[16px] px-[14px] py-[10px] rounded"
            type="text"
            placeholder="Whatsapp"
           
            onChange={(event) => {
              let newFormData = { ...formData };
              newFormData.business.socialMedia.whatsapp = event.target.value;
              setFormData({ ...newFormData });
            }}
          />
        </div>

        <div className="w-full mt-4">
          <label className="text-left text-[14px] font-medium text-gray-7 mb-1 block">
            Facebook
          </label>
          <input
            className="w-full border border-gray-3 focus:outline-0 focus:border-primary text-[16px] px-[14px] py-[10px] rounded"
            type="text"
            placeholder="Facebook"
            
            onChange={(event) => {
              let newFormData = { ...formData };
              newFormData.business.socialMedia.facebook = event.target.value;
              setFormData({ ...newFormData });
            }}
          />
        </div>

        <div className="text-center mt-5">
          <MuiButton
            onClick={handleClick}
            className="bg-primary w-full text-white capitalize text-[16px] py-[10px] px-[40px] rounded"
          >
            next
          </MuiButton>
        </div>
      </form>
    </section>
  );
};

export default SocialMedia;
