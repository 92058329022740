import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RoutedTab } from "../../../../components/Directory";
import { useCustomer as useCustomerState } from "../../../../hooks";
import { COUNTRIES } from "./script";
import { ProductView } from "./components";

import {
  Button as MuiButton,
  Select as MuiSelect,
  Checkbox as MuiCheckbox,
  MenuItem as MuiMenuItem,
  Grid as MuiGrid,
} from "@mui/material/";

import services from "../../../../services";

export default function Update() {
  const [submitProgress, setSubmitProgress] = useState(false);
  const navigate = useNavigate();
  const [customerState, setCustomerState] = useCustomerState();
  const [formData, setFormData] = useState(
    customerState.customer?.directory?.directory
  );

  useEffect(() => {
    setFormData(customerState.customer?.directory?.directory);
  }, [customerState]);

  return (
    <div className="pt-[70px] px-2 md:px-6 font-dmSans ">
      <RoutedTab />

      <section className="my-5">
        <h2 className="font-medium text-[20px] text-gray-9">
          Update directory
        </h2>
      </section>

      <form className="">
        <section className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <article>
            <h3 className="text-gray-9 font-medium text-[16px]">
              Business information
            </h3>

            <div className="mt-3 border border-gray-3 rounded py-[30px] px-[24px]">
              <div className="w-full">
                <label className="text-left text-[14px] font-medium text-gray-7 mb-1 block">
                  Business Name
                </label>
                <input
                  className="w-full border focus:outline-0 focus:border-primary text-[16px] border-gray-3 px-[14px] py-[10px] rounded"
                  type="text"
                  value={formData.business?.name}
                  onChange={(event) => {
                    let newFormData = { ...formData };
                    newFormData.business.name = event.target.value;
                    setFormData({ ...newFormData });
                  }}
                />
              </div>

              <div className="w-full mt-3">
                <label className="text-left text-[14px] font-medium text-gray-7 mb-1 block">
                  About business
                </label>
                <textarea
                  className="w-full border border-gray-3 focus:outline-0 focus:border-primary text-[16px] px-[14px] py-[10px] rounded"
                  type="text"
                  rows="5"
                  value={formData.business?.story}
                  onChange={(event) => {
                    let newFormData = { ...formData };
                    newFormData.business.story = event.target.value;
                    setFormData({ ...newFormData });
                  }}
                ></textarea>
              </div>
              <div className="w-full mt-3">
                <label className="text-left text-[14px] font-medium text-gray-7 mb-1 block">
                  Country
                </label>
                <MuiSelect
                  defaultValue={formData.country}
                  value={formData.country}
                  onChange={(event) => {
                    let newFormData = { ...formData };
                    newFormData.country = event.target.value;
                    setFormData({ ...newFormData });
                  }}
                  className="w-[100%] h-[52px] border border-gray-3 focus:outline-0 focus:border-primary px-[14px] rounded"
                >
                  {COUNTRIES.map((country, index) => {
                    return (
                      <MuiMenuItem key={index} value={country}>
                        {country}
                      </MuiMenuItem>
                    );
                  })}
                </MuiSelect>
              </div>
            </div>
          </article>

          <article>
            <h3 className="text-gray-9 font-medium text-[16px] mb-3">
              Products
            </h3>
            <div className=" border border-gray-3 rounded  py-[30px] px-[24px]">
              <MuiGrid container spacing={1}>
                {customerState.customer?.directory?.directory?.products
                  ?.length < 1 ? (
                  <h1>You have no products</h1>
                ) : (
                  <>
                    {customerState.customer?.directory?.directory?.products?.map(
                      (item, index) => {
                        return (
                          <ProductView
                            key={index}
                            item={item}
                            formData={formData}
                            setFormData={setFormData}
                          />
                        );
                      }
                    )}
                  </>
                )}
              </MuiGrid>
            </div>
          </article>
        </section>

        <section className="mt-5 grid grid-cols-1 lg:grid-cols-2 gap-4">
          <div className="">
            <article className="">
              <h3 className="text-gray-9 font-medium text-[16px] mb-3">
                Personal Information
              </h3>

              <div className=" border border-gray-3 rounded  py-[30px] px-[24px]">
                <div className="w-full">
                  <label className="text-left text-[14px] font-medium text-gray-7 mb-1 block">
                    About yourself
                  </label>
                  <textarea
                    className="w-full border border-gray-3 focus:outline-0 focus:border-primary text-[16px] px-[14px] py-[10px] rounded"
                    type="text"
                    rows="5"
                    value={formData.story}
                    onChange={(event) => {
                      let newFormData = { ...formData };
                      newFormData.story = event.target.value;
                      setFormData({ ...newFormData });
                    }}
                  ></textarea>
                </div>
                <div className="w-full mt-4">
                  <label className="text-left text-[14px] font-medium text-gray-7 mb-1 block">
                    Username
                  </label>

                  <input
                    className="w-full border border-gray-3 focus:outline-0 focus:border-primary text-[16px] px-[14px] py-[10px] rounded"
                    type="text"
                    disabled
                    value={formData.username}
                  />
                </div>
              </div>
            </article>

            {/* <article className="mt-4">
              <h3 className="text-gray-9 font-medium text-[16px] mb-3">
                Selling Type
              </h3>

              <div className=" border border-gray-3 rounded  p-[20px]">
                {[].map((item) => (
                  <div key={item?.id} className="flex items-center mb-[1px]">
                    <MuiCheckbox
                      size="small"
                      sx={{
                        color: "#667085",
                        "&.Mui-checked": {
                          color: "#667085",
                        },
                      }}
                    />
                    <label className="text-gray-7 text-[14px] font-medium">
                      {item?.label}
                    </label>
                  </div>
                ))}
              </div>
            </article> */}
          </div>

          <article>
            <h3 className="text-gray-9 font-medium text-[16px] mb-3">
              Social Media
            </h3>

            <div className=" border border-gray-3 rounded  py-[30px] px-[24px]">
              <div className="w-full">
                <label className="text-left text-[14px] font-medium text-gray-7 mb-1 block">
                  Facebook
                </label>
                <input
                  className="w-full border border-gray-3 focus:outline-0 focus:border-primary text-[16px] px-[14px] py-[10px] rounded"
                  type="text"
                  value={formData.business?.socialMedia?.facebook}
                  onChange={(event) => {
                    let newFormData = { ...formData };
                    newFormData.business.socialMedia.facebook =
                      event.target.value;
                    setFormData({ ...newFormData });
                  }}
                />
              </div>

              <div className="w-full mt-4">
                <label className="text-left text-[14px] font-medium text-gray-7 mb-1 block">
                  LinkedIn
                </label>

                <input
                  className="w-full border border-gray-3 focus:outline-0 focus:border-primary text-[16px] px-[14px] py-[10px] rounded"
                  type="text"
                  value={formData.business?.socialMedia?.linkedin}
                  onChange={(event) => {
                    let newFormData = { ...formData };
                    newFormData.business.socialMedia.linkedin =
                      event.target.value;
                    setFormData({ ...newFormData });
                  }}
                />
              </div>
              <div className="w-full mt-4">
                <label className="text-left text-[14px] font-medium text-gray-7 mb-1 block">
                  Twitter
                </label>

                <input
                  className="w-full border border-gray-3 focus:outline-0 focus:border-primary text-[16px] px-[14px] py-[10px] rounded"
                  type="text"
                  value={formData.business?.socialMedia?.twitter}
                  onChange={(event) => {
                    let newFormData = { ...formData };
                    newFormData.business.socialMedia.twitter =
                      event.target.value;
                    setFormData({ ...newFormData });
                  }}
                />
              </div>
              <div className="w-full mt-4">
                <label className="text-left text-[14px] font-medium text-gray-7 mb-1 block">
                  WhatsApp
                </label>

                <input
                  className="w-full border border-gray-3 focus:outline-0 focus:border-primary text-[16px] px-[14px] py-[10px] rounded"
                  type="text"
                  value={formData.business?.socialMedia?.whatsapp}
                  onChange={(event) => {
                    let newFormData = { ...formData };
                    newFormData.business.socialMedia.whatsapp =
                      event.target.value;
                    setFormData({ ...newFormData });
                  }}
                />
              </div>
              <div className="w-full mt-4">
                <label className="text-left text-[14px] font-medium text-gray-7 mb-1 block">
                  Instagram
                </label>

                <input
                  className="w-full border border-gray-3 focus:outline-0 focus:border-primary text-[16px] px-[14px] py-[10px] rounded"
                  type="text"
                  value={formData.business?.socialMedia?.instagram}
                  onChange={(event) => {
                    let newFormData = { ...formData };
                    newFormData.business.socialMedia.instagram =
                      event.target.value;
                    setFormData({ ...newFormData });
                  }}
                />
              </div>
            </div>

            <div className="text-right mt-4">
              <MuiButton
                disabled={submitProgress}
                onClick={(event) => {
                  setSubmitProgress(true);
                  const finalForm = { ...formData };
                  services.accounts.customers.directory
                    .update(
                      customerState.customer.directory.directory._id,
                      "update",
                      finalForm
                    )
                    .then(() => {
                      alert("update successfully!");
                      setSubmitProgress(false);
                    })
                    .catch((error) => {
                      alert(error.message);
                      setSubmitProgress(false);
                    });
                }}
                className="normal-case  bg-primary w-fit text-white text-[16px] py-[10px] px-[14px] rounded"
              >
                {submitProgress ? "Uploading changes..." : "Update my profile"}
              </MuiButton>
            </div>
          </article>
        </section>
      </form>
    </div>
  );
}
