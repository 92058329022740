import React from "react";
import { Link } from "react-router-dom";

const ProductCard = ({ item }) => {
  return (
    <Link to={`/directory/product/${item._id}`} state={{ product: item }}>
      <article className="cursor-pointer shadow-5xl rounded-[8px] py-4 px-3 bg-white">
        <div className="h-[150px] rounded bg-gray-100 mb-2 relative">
          <img
            src={item?.images[0]}
            alt="product"
            className="w-full h-full object-cover"
          />
        </div>

        <div>
          <span className="block text-[20px] font-medium text-gray-9 ">
            {item.name}
          </span>

          <span className="text-[16px] font-bold text-gray-5 mt-2 ">
            {`${item.currency}  ${item.price}`}
          </span>
        </div>
      </article>
    </Link>
  );
};

export default ProductCard;
