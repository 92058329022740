import { Routes, Route } from "react-router-dom";

import { Reset, Update } from "./pages";

export default function Password() {
  return (
    <Routes>
      <Route path={"/reset"} element={<Reset />} />
      <Route path={"/update"} element={<Update />} />
    </Routes>
  );
}
