import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Hidden as MuiHidden,
  Grid as MuiGrid,
  Slide as MuiSlide,
  Button as MuiButton,
  Box as MuiBox,
  TextField as MuiTextField,
  Typography as MuiTypography,
} from "@mui/material";
import { useComponentsState } from "../../../../hooks";

import services from "../../../../services";
import { AlertV1 } from "../../../../components";

const Reset = () => {
  const navigate = useNavigate();
  const { loaderV1, alertV1 } = useComponentsState();
  const [otp, setOtp] = useState(["", "", "", "", ""]);
  const [activeStep, setActiveStep] = useState(1);
  const [waitTime, setWaitTime] = useState(60);
  const inputRefs = useRef([]);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    loaderV1.close();
  }, []);

  useEffect(() => {
    setFormData({ ...formData, resetToken: otp.join("") });
  }, [otp]);

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  const handleInputChange = (e, index) => {
    const value = e.target.value;
    setOtp((prevOtp) => {
      const newOtp = [...prevOtp];
      console.log(newOtp);
      newOtp[index] = value;
      return newOtp;
    });
    if (value !== "") {
      if (index === otp.length - 1) {
        inputRefs.current[index].blur();
      } else {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "") {
      if (index === 0) {
        return;
      }
      inputRefs.current[index - 1].focus();
    }
  };
  return (
    <MuiGrid
      container={true}
      justifyContent={"center"}
      className="w-[100%] min-h-[100vh]"
    >
      <MuiHidden only={["sm", "xs"]}>
        <MuiGrid item={true} md={6} className="bg-[#0f0108]">
          <div className="flex items-center ml-[10%] mt-[40px] mb-5">
            <h1 className="text-[#c3b5bd]">shecluded</h1>
            <img
              className="w-[20.32px] h-[40.92px] pb-[7px] ml-[-2px]"
              src={""}
              alt=""
            />
          </div>
          <div className="w-[100%] flex flex-col items-center space-y-[40px] mt-4">
            <h1 className="text-[white] space-x-2 text-[32px] ml-[-17%] font-lato">
              <span>Powering</span>
              <span className="text-[#ED1F8E] font-playfair">Finance</span>
              <span>For Women</span>
            </h1>
          </div>
        </MuiGrid>
      </MuiHidden>

      <MuiGrid
        item={true}
        xs={12}
        sm={10}
        md={6}
        className="flex flex-col bg-[white]"
      >
        <MuiSlide in={activeStep === 1} exit={false} mountOnEnter unmountOnExit>
          <MuiBox className="w-[100%] h-[100%] d-flex flex-column justify-content-center">
            <MuiBox className="text-center my-[20px]">
              <h1 className="text-[32px] font-lato">
                Provide your email address
              </h1>
              <p className="text-[16px] text-[#575757]">
                A reset token will be sent to this email address
              </p>
            </MuiBox>
            <MuiBox className="mt-[20px] w-[90%] md:w-[60%] mx-auto">
              <MuiTypography className="font-lato">
                {"Email address"}
              </MuiTypography>
              <MuiTextField
                placeholder="Enter email address"
                variant={"outlined"}
                className={"w-[100%]"}
                onChange={(event) => {
                  setFormData({
                    ...formData,
                    emailAddress: event.target.value,
                  });
                }}
              />
            </MuiBox>

            <MuiBox className="mt-[20px] w-[90%] md:w-[60%] mx-auto">
              <MuiButton
                disableElevation={true}
                variant="contained"
                className="w-[100%] bg-[#ED1F8E] py-[10px] text-capitalize"
                onClick={() => {
                  services.accounts.customers.password.resetToken
                    .generate(formData.emailAddress)
                    .then((data) => {
                      alertV1.open(data.message);
                      setActiveStep(2);
                    })
                    .catch((error) => {
                      alertV1.open(error.message);
                    });
                }}
              >
                {"Send my reset token"}
              </MuiButton>
            </MuiBox>
          </MuiBox>
        </MuiSlide>
        <MuiSlide in={activeStep === 2} exit={false} mountOnEnter unmountOnExit>
          <MuiBox className="h-[100%] d-flex flex-column justify-content-center">
            <MuiBox className="text-center my-[20px]">
              <h1 className="text-[32px] font-lato">Reset your password</h1>
              <p className="text-[16px] text-[#575757]">
                Please enter the OTP sent to your mail
              </p>
            </MuiBox>
            <MuiBox className="flex justify-content-center my-[20px]">
              {otp.map((value, index) => {
                return (
                  <input
                    key={index}
                    className="border border-[#C2C3C8] mx-[5px] rounded-[10px] w-[40px] h-[40px] text-center"
                    type="text"
                    maxLength={1}
                    value={value}
                    onChange={(e) => handleInputChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    ref={(ref) => (inputRefs.current[index] = ref)}
                  />
                );
              })}
            </MuiBox>
            <MuiBox>
              {/* <p className="text-[16px] font-lato text-[#575757]">
              Resend code: 56s
            </p> */}
            </MuiBox>

            <MuiBox className={"w-[90%] mx-auto md:w-[70%]"}>
              <MuiButton
                disableElevation={true}
                variant="contained"
                className="w-[100%] bg-[#ED1F8E] text-capitalize"
                onClick={() => {
                  services.accounts.customers.password.resetToken
                    .verify(formData.emailAddress, formData.resetToken)
                    .then((data) => {
                      alertV1.open(data.message);
                      setActiveStep(3);
                    })
                    .catch((error) => {
                      alertV1.open(error.message);
                    });
                }}
              >
                {"Submit"}
              </MuiButton>
            </MuiBox>
          </MuiBox>
        </MuiSlide>
        <MuiSlide in={activeStep === 3} exit={false} mountOnEnter unmountOnExit>
          <MuiBox className="h-[100%] d-flex flex-column justify-content-center">
            <MuiBox className="text-center my-[20px]">
              <h1 className="text-[32px] font-lato">Enter your new password</h1>
            </MuiBox>
            <MuiBox className="w-[90%] md:w-[70%] mx-auto  my-[5px]">
              <MuiTypography>{"New password"}</MuiTypography>
              <MuiTextField
                placeholder="Enter new password"
                variant={"outlined"}
                className={"w-[100%]"}
                type={"password"}
                onChange={(event) => {
                  setFormData({ ...formData, newPassword: event.target.value });
                }}
              />
            </MuiBox>
            <MuiBox className="w-[90%] md:w-[70%] mx-auto  my-[5px]">
              <MuiTypography>{"Confirm new password"}</MuiTypography>
              <MuiTextField
                placeholder="Re-enter new password"
                variant={"outlined"}
                type={"password"}
                className={"w-[100%]"}
                onChange={(event) => {
                  setFormData({
                    ...formData,
                    confirmNewPassword: event.target.value,
                  });
                }}
              />
            </MuiBox>
            <MuiBox>
              {/* <p className="text-[16px] font-lato text-[#575757]">
              Resend code: 56s
            </p> */}
            </MuiBox>

            <MuiBox className={"w-[90%] md:w-[70%] mx-auto my-[20px]"}>
              <MuiButton
                disableElevation={true}
                variant="contained"
                className="w-[100%] bg-[#ED1F8E] text-capitalize"
                onClick={() => {
                  if (formData.newPassword !== formData.confirmNewPassword) {
                    alertV1.open("Password fields do not match");
                    return false;
                  }
                  services.accounts.customers.password
                    .reset(formData.emailAddress, formData.newPassword)
                    .then((data) => {
                      alertV1.open(data.message);
                      navigate("/signin");
                    })
                    .catch((error) => {
                      alertV1.open(error.message);
                    });
                }}
              >
                {"Change my password"}
              </MuiButton>
            </MuiBox>
          </MuiBox>
        </MuiSlide>
      </MuiGrid>
    </MuiGrid>
  );
};

export default Reset;
