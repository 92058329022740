import * as Mui from "@mui/material";
import { useComponentsState } from "../../hooks";

function AlertV1(props) {
  const { componentsState, alertV1 } = useComponentsState();
  return (
    <Mui.Snackbar
      open={componentsState.alertV1.isOpen}
      autoHideDuration={2000}
      onClose={alertV1.close}
      message={componentsState.alertV1.message}
    />
  );
}

export default AlertV1;
