export const business_category = [
  "Arts",
  "Advertising & Marketing",
  "Agriculture & Farming",
  "Artisanal & Handcrafted Goods",
  "Automobile",
  "Beauty & Personal Care",
  "Business Consulting",
  "Cleaning & Janitorial Services",
  "Construction & Contractors",
  "Education & Training",
  "Energy",
  "Entertainment & Media",
  "Event Planning & Management",
  "Fashion",
  "Financial Services",
  "Food & Beverage",
  "Health & Wellness",
  "Interior Design & Home Decor",
  "Hospitality & Travel",
  "Legal Services",
  "Manufacturing",
  "Social Enterprise",
  "Social Media & Digital Marketing",
  "Real Estate & Property Management",
  "Retail & E-commerce",
  "Transportation & Logistics",
  "Software & Technology",
  "Sports & Recreation",
  "Telecommunications & Internet Services",
  "Wholesale & Distribution",
  "Professional/Business Services",
  "Environmental Services",
  "Wedding Planning & Bridal Services",
  "Photography & Videography",
  "Human Resources",
  "Printing & Publishing",
  "Pet Care & Veterinary Services",
  "Coaching & Personal Development",
  "Other",
];
