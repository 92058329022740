import { useState, useEffect } from "react";
import {
  Grid as MuiGrid,
  Slider as MuiSlider,
  Box as MuiBox,
  Typography as MuiTypography,
} from "@mui/material";
import { useComponentsState } from "../../../../hooks";
import { SidebarV1, HeaderV1 } from "../../../../components";

function Calculator() {
  const { componentsState, setComponentsState } = useComponentsState();
  const [pageReadyState, setPageReadyState] = useState(0);

  useEffect(() => {
    if (pageReadyState === 1) {
      setTimeout(() => {
        setComponentsState({ ...componentsState, loaderV1: { isOpen: false } });
      }, 500);
    }
  }, [pageReadyState]);

  useEffect(() => {
    // if (customerState.isSignedIn === false) {
    //   navigate("/signin");
    // } else {
    //   setTimeout(() => {
    //     setPageReadyState(pageReadyState + 1);
    //   }, 500);
    // }

    //TO BE REMOVED TO CHECK FOR AUTH STATE
    setTimeout(() => {
      setPageReadyState(pageReadyState + 1);
    }, 500);

    return () => {
      setComponentsState({ ...componentsState, loaderV1: { isOpen: true } });
    };
  }, []);

  return (
    pageReadyState === 1 && (
      <MuiGrid>
        <HeaderV1 />
        <MuiGrid container={true}>
          <MuiGrid item={true}>
            <SidebarV1 selectedIndex={2} />
          </MuiGrid>
          <MuiGrid item={true} className={"flex-grow-1 bg-light py-5"}>
            <MuiBox
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginBottom: "40px",
              }}
            >
              <MuiBox sx={{ width: "95%" }}>
                <MuiBox>
                  <MuiTypography sx={{ fontSize: "24px", fontWeight: "bold" }}>
                    {`Loan Calculator`}
                  </MuiTypography>
                  {/* <MuiTypography sx={{ color: "gray" }}>
                    {"View your loan history here"}
                  </MuiTypography> */}
                </MuiBox>
              </MuiBox>
            </MuiBox>
          </MuiGrid>
        </MuiGrid>
      </MuiGrid>
    )
  );
}

export default Calculator;
