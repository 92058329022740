import { useState, useEffect, Fragment } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useComponentsState,
  useCustomer as useCustomerState,
} from "../../../../hooks";
import {
  Box,
  Typography,
  Card,
  Button,
  Grid as MuiGrid,
  Card as MuiCard,
  CardContent as MuiCardContent,
  CardActions as MuiCardActions,
  Button as MuiButton,
  Typography as MuiTypography,
  LinearProgress as MuiLinearProgress,
  Tabs as MuiTabs,
  Tab as MuiTab,
  Slide as MuiSlide,
} from "@mui/material/";
import { SidebarV1, HeaderV1 } from "../../../../components";

import EastIcon from "@mui/icons-material/East";

function View() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { componentsState, setComponentsState } = useComponentsState();
  const [customerState, setCustomerState] = useCustomerState();
  const [pageReadyState, setPageReadyState] = useState(0);
  const [viewMode, setViewMode] = useState("history");
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (pageReadyState === 1) {
      setTimeout(() => {
        setComponentsState({ ...componentsState, loaderV1: { isOpen: false } });
      }, 500);
    }
  }, [pageReadyState]);

  useEffect(() => {
    // if (customerState.isSignedIn === false) {
    //   navigate("/signin");
    // } else {
    //   setTimeout(() => {
    //     setPageReadyState(pageReadyState + 1);
    //   }, 500);
    // }

    //TO BE REMOVED TO CHECK FOR AUTH STATE
    setTimeout(() => {
      setPageReadyState(pageReadyState + 1);
    }, 500);
    console.log(searchParams.get("mode"));
    return () => {
      setComponentsState({ ...componentsState, loaderV1: { isOpen: true } });
    };
  }, []);

  return (
    pageReadyState === 1 && (
      <MuiGrid className={"position-absolute top-0 bottom-0 start-0 end-0"}>
        <HeaderV1 />
        <MuiGrid container={true} wrap={"nowrap"}>
          <MuiGrid item={true}>
            <SidebarV1 selectedIndex={2} />
          </MuiGrid>
          <MuiGrid item={true} className={"flex-grow-1 bg-light"}>
            {searchParams.get("mode") === "single" && (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "40px",
                }}
              >
                <Box sx={{ width: "95%" }}>
                  <Box>
                    <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
                      {`Vacation trip to dubai`}
                    </Typography>
                    <Typography sx={{ color: "gray" }}>
                      {"loan details"}
                    </Typography>
                  </Box>
                  <MuiGrid>
                    <MuiGrid
                      className={"px-3 bg-white my-3 py-4 px-3"}
                      container={true}
                      justifyContent={"space-between"}
                    >
                      <MuiGrid className="flex items-center h-[100%] space-x-5">
                        <Box>
                          <MuiTypography className="fw-bold text-[#0E0008]">
                            {"Loan Amount"}
                          </MuiTypography>
                          <MuiTypography
                            variant="h2"
                            className="text-[#0E0008]"
                          >
                            {(500000).toLocaleString()}
                          </MuiTypography>
                        </Box>
                      </MuiGrid>
                      <MuiGrid className={"h-100 d-flex align-items-center"}>
                        <MuiButton color={"secondary"} variant={"contained"}>
                          {"Repay"}
                        </MuiButton>
                      </MuiGrid>
                      <MuiGrid className={"w-100 py-4"}>
                        <MuiLinearProgress variant="determinate" value={80} />
                      </MuiGrid>
                    </MuiGrid>
                  </MuiGrid>
                  <MuiGrid>
                    <MuiGrid>
                      <MuiTabs
                        value={activeTab}
                        onChange={(event, number) => {
                          setActiveTab(number);
                        }}
                        TabIndicatorProps={{ className: "d-none" }}
                      >
                        <MuiTab label={"Payment schedule"} />
                        <MuiTab label={"Transactions"} />
                      </MuiTabs>
                    </MuiGrid>
                    <MuiGrid>
                      <MuiSlide
                        in={activeTab === 0}
                        direction={"left"}
                        mountOnEnter
                        unmountOnExit
                        exit={false}
                      >
                        <MuiGrid className="bg-white p-3">
                          <h1>No schedule to show</h1>
                        </MuiGrid>
                      </MuiSlide>
                      <MuiSlide
                        in={activeTab === 1}
                        direction={"left"}
                        mountOnEnter
                        unmountOnExit
                        exit={false}
                      >
                        <MuiGrid className="bg-white p-3">
                          <h1 className="">No Transactions to show</h1>
                        </MuiGrid>
                      </MuiSlide>
                    </MuiGrid>
                  </MuiGrid>
                </Box>
              </Box>
            )}
            {searchParams.get("mode") === "history" && (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "40px",
                }}
              >
                <Box sx={{ width: "95%" }}>
                  <Box>
                    <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
                      {`Loans`}
                    </Typography>
                    <Typography sx={{ color: "gray" }}>
                      {"your loan history"}
                    </Typography>
                  </Box>
                  <MuiGrid>
                    <MuiGrid className={"mt-3"}>
                      <MuiTabs
                        value={activeTab}
                        onChange={(event, number) => {
                          setActiveTab(number);
                        }}
                        TabIndicatorProps={{ className: "d-none" }}
                      >
                        {[
                          { label: "All" },
                          { label: "Active" },
                          { label: "Submitted" },
                          { label: "Approved" },
                          { label: "Rejected" },
                        ].map((item, index) => {
                          return (
                            <MuiTab
                              classes={{
                                root: `text-[13px] text-capitalize border border-dark rounded-pill ${
                                  index === 0 ? "me-1" : "mx-1"
                                }`,
                                selected: `text-white bg-black border-2 border-blue-900`,
                              }}
                              label={item.label}
                            />
                          );
                        })}
                      </MuiTabs>
                    </MuiGrid>
                    <MuiGrid>
                      <MuiSlide
                        in={activeTab === 0}
                        direction={"left"}
                        mountOnEnter
                        unmountOnExit
                        exit={false}
                      >
                        <MuiGrid className="bg-white p-3">
                          {customerState.loans.map((item, index) => {
                            if (item.status === "ACTIVE") {
                              return (
                                <MuiGrid
                                  className={"px-3 bg-white my-3 py-4 px-3"}
                                  container={true}
                                  justifyContent={"space-between"}
                                  key={index}
                                >
                                  <MuiGrid className="flex items-center h-[100%] space-x-5">
                                    <Box>
                                      <h1 className="text-[15px] text-[#0E0008]">
                                        {item.title}
                                      </h1>
                                    </Box>
                                  </MuiGrid>
                                  <MuiGrid
                                    className={
                                      "h-100 d-flex align-items-center"
                                    }
                                  >
                                    <MuiTypography>{item.amount}</MuiTypography>
                                  </MuiGrid>
                                  <MuiGrid className={"w-100 py-4"}>
                                    <MuiLinearProgress
                                      variant="determinate"
                                      value={80}
                                    />
                                  </MuiGrid>
                                </MuiGrid>
                              );
                            }

                            return (
                              <MuiGrid
                                className={"px-3 bg-white my-3 py-4 px-3"}
                                container={true}
                                justifyContent={"space-between"}
                                key={index}
                              >
                                <MuiGrid className="flex items-center h-[100%] space-x-5">
                                  <Box>
                                    <h1 className="text-[15px] text-[#0E0008]">
                                      {item.title}
                                    </h1>
                                  </Box>
                                </MuiGrid>
                                <MuiGrid
                                  className={"h-100 d-flex align-items-center"}
                                >
                                  {item.status === "SUBMITTED" && (
                                    <Fragment>
                                      <MuiButton
                                        className={"Y23232 p-2 me-1"}
                                        variant="contained"
                                        disableElevation
                                        disableRipple
                                      >
                                        Cancel Request
                                      </MuiButton>
                                      <MuiButton
                                        className={"G4343 p-2"}
                                        variant="contained"
                                        disableElevation
                                        disableRipple
                                        onClick={() => {
                                          navigate("/loans/view?mode=single");
                                        }}
                                      >
                                        View Details
                                      </MuiButton>
                                    </Fragment>
                                  )}
                                  {item.status === "APPROVED" && (
                                    <Fragment>
                                      <MuiButton
                                        className={"U232323 p-2 me-1"}
                                        variant="contained"
                                        disableElevation
                                        disableRipple
                                      >
                                        View Offer
                                      </MuiButton>
                                      <MuiButton
                                        className={"G4343 p-2"}
                                        variant="contained"
                                        disableElevation
                                        disableRipple
                                        onClick={() => {
                                          navigate("/loans/view?mode=single");
                                        }}
                                      >
                                        View Details
                                      </MuiButton>
                                    </Fragment>
                                  )}
                                  {item.status === "REJECTED" && (
                                    <Fragment>
                                      <MuiButton
                                        className={"F32323 p-2 me-1"}
                                        variant={"contained"}
                                        disableElevation
                                        disableRipple
                                      >
                                        View Reason
                                      </MuiButton>
                                      <MuiButton
                                        className={"G4343 p-2"}
                                        variant="contained"
                                        disableElevation
                                        disableRipple
                                        onClick={() => {
                                          navigate("/loans/view?mode=single");
                                        }}
                                      >
                                        View Details
                                      </MuiButton>
                                    </Fragment>
                                  )}
                                </MuiGrid>
                              </MuiGrid>
                            );
                          })}
                        </MuiGrid>
                      </MuiSlide>
                      <MuiSlide
                        in={activeTab === 1}
                        direction={"left"}
                        mountOnEnter
                        unmountOnExit
                        exit={false}
                      >
                        <MuiGrid className="bg-white p-3">
                          {customerState.loans.map((item, index) => {
                            if (item.status === "ACTIVE") {
                              return (
                                <MuiGrid
                                  className={"px-3 bg-white my-3 py-4 px-3"}
                                  container={true}
                                  justifyContent={"space-between"}
                                  key={index}
                                >
                                  <MuiGrid className="flex items-center h-[100%] space-x-5">
                                    <Box>
                                      <h1 className="text-[15px] text-[#0E0008]">
                                        {item.title}
                                      </h1>
                                    </Box>
                                  </MuiGrid>
                                  <MuiGrid
                                    className={
                                      "h-100 d-flex align-items-center"
                                    }
                                  >
                                    <MuiTypography>{item.amount}</MuiTypography>
                                  </MuiGrid>
                                  <MuiGrid className={"w-100 py-4"}>
                                    <MuiLinearProgress
                                      variant="determinate"
                                      value={80}
                                    />
                                  </MuiGrid>
                                </MuiGrid>
                              );
                            }
                          })}
                        </MuiGrid>
                      </MuiSlide>
                      <MuiSlide
                        in={activeTab === 2}
                        direction={"left"}
                        mountOnEnter
                        unmountOnExit
                        exit={false}
                      >
                        <MuiGrid className="bg-white p-3">
                          {customerState.loans.map((item, index) => {
                            if (item.status === "SUBMITTED") {
                              return (
                                <MuiGrid
                                  className={"px-3 bg-white my-3 py-4 px-3"}
                                  container={true}
                                  justifyContent={"space-between"}
                                  key={index}
                                >
                                  <MuiGrid className="flex items-center h-[100%] space-x-5">
                                    <Box>
                                      <h1 className="text-[15px] text-[#0E0008]">
                                        {item.title}
                                      </h1>
                                    </Box>
                                  </MuiGrid>
                                  <MuiGrid
                                    className={
                                      "h-100 d-flex align-items-center"
                                    }
                                  >
                                    {item.status === "SUBMITTED" && (
                                      <Fragment>
                                        <MuiButton
                                          className={"Y23232 p-2 me-1"}
                                          variant="contained"
                                          disableElevation
                                          disableRipple
                                        >
                                          Cancel Request
                                        </MuiButton>
                                        <MuiButton
                                          className={"G4343 p-2"}
                                          variant="contained"
                                          disableElevation
                                          disableRipple
                                          onClick={() => {
                                            navigate("/loans/view?mode=single");
                                          }}
                                        >
                                          View Details
                                        </MuiButton>
                                      </Fragment>
                                    )}
                                  </MuiGrid>
                                </MuiGrid>
                              );
                            }
                          })}
                        </MuiGrid>
                      </MuiSlide>
                      <MuiSlide
                        in={activeTab === 3}
                        direction={"left"}
                        mountOnEnter
                        unmountOnExit
                        exit={false}
                      >
                        <MuiGrid className="bg-white p-3">
                          {customerState.loans.map((item, index) => {
                            if (item.status === "APPROVED") {
                              return (
                                <MuiGrid
                                  className={"px-3 bg-white my-3 py-4 px-3"}
                                  container={true}
                                  justifyContent={"space-between"}
                                  key={index}
                                >
                                  <MuiGrid className="flex items-center h-[100%] space-x-5">
                                    <Box>
                                      <h1 className="text-[15px] text-[#0E0008]">
                                        {item.title}
                                      </h1>
                                    </Box>
                                  </MuiGrid>
                                  <MuiGrid
                                    className={
                                      "h-100 d-flex align-items-center"
                                    }
                                  >
                                    {item.status === "APPROVED" && (
                                      <Fragment>
                                        <MuiButton
                                          className={"U232323 p-2 me-1"}
                                          variant="contained"
                                          disableElevation
                                          disableRipple
                                        >
                                          View Offer
                                        </MuiButton>
                                        <MuiButton
                                          className={"G4343 p-2"}
                                          variant="contained"
                                          disableElevation
                                          disableRipple
                                          onClick={() => {
                                            navigate("/loans/view?mode=single");
                                          }}
                                        >
                                          View Details
                                        </MuiButton>
                                      </Fragment>
                                    )}
                                  </MuiGrid>
                                </MuiGrid>
                              );
                            }
                          })}
                        </MuiGrid>
                      </MuiSlide>
                      <MuiSlide
                        in={activeTab === 4}
                        direction={"left"}
                        mountOnEnter
                        unmountOnExit
                        exit={false}
                      >
                        <MuiGrid className="bg-white p-3">
                          {customerState.loans.map((item, index) => {
                            if (item.status === "REJECTED") {
                              return (
                                <MuiGrid
                                  className={"px-3 bg-white my-3 py-4 px-3"}
                                  container={true}
                                  justifyContent={"space-between"}
                                  key={index}
                                >
                                  <MuiGrid className="flex items-center h-[100%] space-x-5">
                                    <Box>
                                      <h1 className="text-[15px] text-[#0E0008]">
                                        {item.title}
                                      </h1>
                                    </Box>
                                  </MuiGrid>
                                  <MuiGrid
                                    className={
                                      "h-100 d-flex align-items-center"
                                    }
                                  >
                                    {item.status === "REJECTED" && (
                                      <Fragment>
                                        <MuiButton
                                          className={"F32323 p-2 me-1"}
                                          variant={"contained"}
                                          disableElevation
                                          disableRipple
                                        >
                                          View Reason
                                        </MuiButton>
                                        <MuiButton
                                          className={"G4343 p-2"}
                                          variant="contained"
                                          disableElevation
                                          disableRipple
                                          onClick={() => {
                                            navigate("/loans/view?mode=single");
                                          }}
                                        >
                                          View Details
                                        </MuiButton>
                                      </Fragment>
                                    )}
                                  </MuiGrid>
                                </MuiGrid>
                              );
                            }
                          })}
                        </MuiGrid>
                      </MuiSlide>
                    </MuiGrid>
                  </MuiGrid>
                </Box>
              </Box>
            )}
          </MuiGrid>
        </MuiGrid>
      </MuiGrid>
    )
  );
}

export default View;
