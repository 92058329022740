import { ReactComponent as DashboardIcon } from "../../assets/dashboard-icon.svg";
import { ReactComponent as DirectoryIcon } from "../../assets/directory-icon.svg";
import { ReactComponent as LoansIcon } from "../../assets/loans-icon.svg";
import { ReactComponent as LearnIcon } from "../../assets/learn-icon.svg";
import { ReactComponent as TransactionIcon } from "../../assets/transaction-icon.svg";
import { ReactComponent as SetttingsIcon } from "../../assets/settings-icon.svg";

export const sidebarItems = [
  {
    title: "Dashboard",
    Icon: DashboardIcon,
    link: "/customer-dashboard",
  },
  {
    title: "Directory",
    Icon: DirectoryIcon,
    link: "/directory",
  },
  {
    title: "Loans",
    Icon: LoansIcon,
    link: "/loans",
  },
  {
    title: "Learn",
    Icon: LearnIcon,
    link: "/learn",
  },
  {
    title: "Transactions",
    Icon: TransactionIcon,
    link: "/transactions",
  },
  {
    title: "Settings",
    Icon: SetttingsIcon,
    link: "/settings",
  },
];
