import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import { RoutedTab } from "../../../../components/Directory";
import { Button as MuiButton, Fade as MuiFade } from "@mui/material/";
import {
  ArrowLeft,
  ArrowRight,
  ArrowBackOutlined,
  ArrowForwardOutlined,
} from "@mui/icons-material";
import { deleteRedIcon, editWhiteIcon } from "../../../../assets";

const ProductId = () => {
  let { state } = useLocation();
  const [carouselPosition, setCarouselPosition] = useState(0);
  const [carouselAutoplay, setCarouselAutoplay] = useState(true);

  useEffect(() => {
    if (carouselAutoplay) {
      setTimeout(() => {
        if (carouselPosition < state.product.images.length - 1) {
          setCarouselPosition(carouselPosition + 1);
          console.log("here here");
        } else {
          setCarouselPosition(0);
        }
      }, 4000);
    }
  }, [carouselPosition]);

  return (
    <div className="pt-[70px] px-2 md:px-6 font-dmSans">
      <RoutedTab />

      <section className="mt-5 shadow-5xl py-4 px-3 bg-white">
        <div className="relative h-60 bg-gray-500 rounded overflow-hidden">
          {state.product.images.length <= 1 ? (
            <img
              src={state?.product?.images[0]}
              alt="banner"
              className="w-full h-full object-cover"
            />
          ) : (
            <>
              {state.product.images.map((item, index) => {
                return (
                  <MuiFade
                    in={carouselPosition === index}
                    unmountOnExit
                    mountOnEnter
                    timeout={200}
                  >
                    <img
                      src={item}
                      alt="banner"
                      className="w-full h-full object-cover"
                    />
                  </MuiFade>
                );
              })}
            </>
          )}
        </div>
        {state.product.images.length > 1 && (
          <div className="py-4 px-3 bg-white d-flex justify-content-between">
            <MuiButton
              onClick={() => {
                setCarouselAutoplay(false);
              }}
            >
              <ArrowBackOutlined className="text-primary" />
            </MuiButton>
            <MuiButton
              onClick={() => {
                setCarouselAutoplay(false);
                if (carouselPosition < state.product.images.length - 1) {
                  setCarouselPosition(carouselPosition + 1);
                } else {
                  setCarouselPosition(0);
                }
              }}
            >
              <ArrowForwardOutlined className="text-primary" />
            </MuiButton>
          </div>
        )}

        <article className="mt-3">
        <h3 className=" text-[20px] font-medium text-gray-9">
              {state?.product?.name}
            </h3>

            <span className="block my-2 font-bold text-[18px] text-gray-5">
              {`${state?.product?.currency} ${state?.product?.price}`}
            </span>

          <p className="w-full xl:w-4/5 text-[16px] text-gray-5">
            {state?.product?.description}
          </p>

          <div className="mt-4 flex items-center">
            {/* <Link to={`/directory/product/edit/${state?.product?._id}`}>
              <MuiButton className="bg-primary w-fit text-white capitalize text-[14px] lg:text-[16px] py-[10px] px-[14px] rounded flex items-center">
                <img src={editWhiteIcon} alt="edit icon" />
                <span className="ml-1 md:ml-2">Edit product</span>
              </MuiButton>
            </Link> */}

            <MuiButton className="bg-[#FEF3F2] w-fit  capitalize text-[14px] lg:text-[16px] py-[10px] px-[14px] rounded flex items-center">
              <img src={deleteRedIcon} alt="delete icon" />
              <span className="ml-1 md:ml-2 text-red-500">delete product</span>
            </MuiButton>
          </div>
        </article>
      </section>
    </div>
  );
};

export default ProductId;
