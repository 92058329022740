import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  useComponentsState,
  useCustomer as useCustomerState,
} from "../../../../hooks";
import {
  Typography as MuiTypography,
  Grid as MuiGrid,
  Button as MuiButton,
  Slide as MuiSlide,
  Stepper as MuiStepper,
  StepConnector as MuiStepConnector,
  Step as MuiStep,
  StepLabel as MuiStepLabel,
} from "@mui/material/";
import { SidebarV1, HeaderV1 } from "../../../../components";

import logo from "../../../../assets/icon.svg";
import checked from "../../../../assets/images/check.svg";
import emoji from "../../../../assets/emoji.svg";
import facebook from "../../../../assets/facebook.svg";
import linkedn from "../../../../assets/linkedn.svg";
import twitter from "../../../../assets/twitter.svg";
import instagram from "../../../../assets/instagram.svg";

function Apply() {
  const navigate = useNavigate();
  const { componentsState, setComponentsState } = useComponentsState();
  const [customerState, setCustomerState] = useCustomerState();
  const [pageReadyState, setPageReadyState] = useState(0);
  const [activeStep, setActiveStep] = useState(1);
  const [stepsCompleted, setStepsCompleted] = useState(false);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (pageReadyState === 1) {
      setTimeout(() => {
        setComponentsState({ ...componentsState, loaderV1: { isOpen: false } });
      }, 500);
    }
  }, [pageReadyState]);

  useEffect(() => {
    // if (customerState.isSignedIn === false) {
    //   navigate("/signin");
    // } else {
    //   setTimeout(() => {
    //     setPageReadyState(pageReadyState + 1);
    //   }, 500);
    // }

    //TO BE REMOVED TO CHECK FOR AUTH STATE
    setTimeout(() => {
      setPageReadyState(pageReadyState + 1);
    }, 500);

    return () => {
      setComponentsState({ ...componentsState, loaderV1: { isOpen: true } });
    };
  }, []);

  return (
    pageReadyState === 1 && (
      <MuiGrid>
        <HeaderV1 />
        <MuiGrid container={true}>
          <MuiGrid item={true}>
            <SidebarV1 selectedIndex={2} />
          </MuiGrid>
          <MuiGrid item={true} className={"flex-grow-1 bg-light"}>
            <div className={"container mx-auto"}>
              <MuiSlide
                exit={false}
                in={stepsCompleted === false}
                mountOnEnter
                unmountOnExit
              >
                <div className={"container"}>
                  <MuiStepper
                    activeStep={activeStep}
                    connector={
                      <MuiStepConnector className={"A113 rounded-3 my-3"} />
                    }
                  >
                    {[...Array(6)].map((item, index) => (
                      <MuiStep key={index}>
                        <MuiStepLabel
                          StepIconComponent={() => {
                            return <span></span>;
                          }}
                        ></MuiStepLabel>
                      </MuiStep>
                    ))}
                  </MuiStepper>
                  <div className={"w-100"}>
                    <MuiSlide
                      in={activeStep === 1}
                      direction={"left"}
                      exit={false}
                      mountOnEnter
                      unmountOnExit
                    >
                      <div className="flex flex-col items-center mt-[70px] ">
                        <h1 className="flex flex-col space-y-2 text-center text-[34px] font-lato font-semibold">
                          <span>Hello, Ifeoma 👋🏼,</span>
                          <span>Let's get some information about you</span>
                        </h1>
                        <div className="flex flex-col items-center w-[40%] mt-[30px] space-y-4">
                          <h1 className="text-[30px]">Where do you Live?</h1>
                          <form className="w-[100%] space-y-[25px]">
                            <input
                              className="w-[100%] border border-[#C2C3C8] h-[52px] rounded-[8px] pl-5"
                              type="text"
                              placeholder="enter address"
                              onChange={(event) => {
                                setFormData({
                                  ...formData,
                                  homeAddress: event.target.value,
                                });
                              }}
                            />
                            <button
                              type="submit"
                              onClick={() => {
                                setActiveStep(2);
                              }}
                              className="w-[100%] h-[52px] text-center bg-[#ED1F8E] rounded-[8px] text-[white]"
                            >
                              Proceed
                            </button>
                          </form>
                        </div>
                      </div>
                    </MuiSlide>
                    <MuiSlide
                      in={activeStep === 2}
                      direction={"left"}
                      exit={false}
                      mountOnEnter
                      unmountOnExit
                    >
                      <div className="flex flex-col items-center mt-3 ">
                        <h1 className=" text-[34px] font-lato font-medium mb-[30px]">
                          Let's get some more
                        </h1>
                        <div className="flex flex-col items-center w-[40%] mt-[30px] space-y-4">
                          <h1 className="text-[30px]">How old are you?</h1>
                          <form className="w-[100%] space-y-[25px]">
                            <input
                              className="w-[100%] border border-[#C2C3C8] h-[52px] rounded-[8px] pl-5"
                              type="text"
                              id="address"
                              name="address"
                              placeholder="DD/MM/YYYY"
                              onChange={(event) => {
                                setFormData({
                                  ...formData,
                                  dateOfBirth: event.target.value,
                                });
                              }}
                            />
                            <button
                              onClick={() => {
                                setActiveStep(3);
                              }}
                              className="w-[100%] h-[52px] text-center bg-[#ED1F8E] rounded-[8px] text-[white]"
                            >
                              Proceed
                            </button>
                          </form>
                        </div>
                      </div>
                    </MuiSlide>
                    <MuiSlide
                      in={activeStep === 3}
                      direction={"left"}
                      exit={false}
                      mountOnEnter
                      unmountOnExit
                    >
                      <div className="flex flex-col items-center mt-3 ">
                        <h1 className=" text-[34px] font-lato font-medium mb-[30px]">
                          Tell us about your love life ❤️
                        </h1>
                        <div className="flex flex-col items-center w-[40%] mt-[30px] space-y-4">
                          <h1 className="text-[30px]">Marital Status</h1>
                          <form className="w-[100%] space-y-[25px]">
                            <select
                              onClick={(event) => {
                                setFormData({
                                  ...formData,
                                  maritalStatus: event.target.value,
                                });
                              }}
                              className="block appearance-none w-full h-[52px] bg-white border border-gray-400 hover:border-gray-500 hover:bg-[red] px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:text-[gray] focus:shadow-outline"
                            >
                              <option value="">Select an option</option>
                              <option value="single">Single</option>
                              <option value="divorced">Divorced</option>
                              <option value="engaged">Engaged</option>
                              <option value="married">Married</option>
                            </select>
                            <button
                              onClick={() => {
                                setActiveStep(4);
                              }}
                              className="w-[100%] h-[52px] text-center bg-[#ED1F8E] rounded-[8px] text-[white]"
                            >
                              Proceed
                            </button>
                          </form>
                        </div>
                      </div>
                    </MuiSlide>
                    <MuiSlide
                      in={activeStep === 4}
                      direction={"left"}
                      exit={false}
                      mountOnEnter
                      unmountOnExit
                    >
                      <div className="flex flex-col items-center mt-3 ">
                        <h1 className=" text-[34px] font-lato font-medium mb-[30px]">
                          Have any kids? Let's know how many?
                        </h1>
                        <div className="flex flex-col items-center w-[40%] mt-[30px] space-y-4">
                          <h1 className="text-[30px]">Kids?</h1>
                          <form className="w-[100%] space-y-[25px]">
                            <input
                              className="w-[100%] border border-[#C2C3C8] h-[52px] rounded-[8px] pl-5"
                              type="number"
                              onChange={(event) => {
                                setFormData({
                                  ...formData,
                                  numberOfChildren: parseInt(
                                    event.target.value
                                  ),
                                });
                              }}
                              placeholder="enter 0 for none"
                            />
                            <button
                              onClick={() => {
                                setActiveStep(5);
                              }}
                              type="submit"
                              className="w-[100%] h-[52px] text-center bg-[#ED1F8E] rounded-[8px] text-[white]"
                            >
                              Proceed
                            </button>
                          </form>
                        </div>
                      </div>
                    </MuiSlide>
                    <MuiSlide
                      in={activeStep === 5}
                      direction={"left"}
                      exit={false}
                      mountOnEnter
                      unmountOnExit
                    >
                      <div className="flex flex-col items-center mt-3 ">
                        <h1 className=" text-[34px] font-lato font-medium mb-[30px]">
                          Tell about how you studied 📚
                        </h1>
                        <div className="flex flex-col items-center w-[40%] mt-[30px] space-y-4">
                          <h1 className="text-[30px]">
                            Highest Education Qualification
                          </h1>
                          <form className="w-[100%] space-y-[25px]">
                            <select
                              onChange={(event) => {
                                setFormData({
                                  ...formData,
                                  highestEducationalQualification:
                                    event.target.value,
                                });
                              }}
                              className="block appearance-none w-full h-[52px] bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:text-[gray] focus:shadow-outline"
                            >
                              <option value="">Select</option>
                              <option value="SSCE">SSCE</option>
                              <option value="ND">National Diploma(ND)</option>
                              <option value="HND">
                                Higher National Diploma(HND)
                              </option>
                              <option value="BSC">Bachelor's Degree</option>
                              <option value="PhD">PhD.</option>
                              <option value="Prof">Prof.</option>
                            </select>
                            <button
                              onClick={() => {
                                setActiveStep(6);
                              }}
                              className="w-[100%] h-[52px] text-center bg-[#ED1F8E] rounded-[8px] text-[white]"
                            >
                              Proceed
                            </button>
                          </form>
                        </div>
                      </div>
                    </MuiSlide>
                    <MuiSlide
                      in={activeStep === 6}
                      direction={"left"}
                      exit={false}
                      mountOnEnter
                      unmountOnExit
                    >
                      <div className="flex flex-col items-center mt-3 ">
                        <div className="text-center font-lato">
                          <div className="flex space-x-2">
                            <h1 className=" text-[34px]  font-medium ">
                              Are you on social media? Tell us
                            </h1>
                            <img src={emoji} alt="" />
                          </div>
                          <p className="text-[#575757]">
                            Tell us the ones you are on and leave the rest
                          </p>
                        </div>
                        <div className="flex flex-col items-center w-[60%] mt-[30px] space-y-10">
                          <form className="w-[100%] space-y-[60px] mt-4">
                            <div className="flex space-x-2">
                              <div className="relative w-[50%]">
                                <label className="absolute mt-[-30px]">
                                  Facebook
                                </label>
                                <img
                                  src={facebook}
                                  alt="wewe"
                                  className="absolute inset-y-3 left-3 h-[] w-[]"
                                />
                                <input
                                  className="w-[100%] pl-10 pr-4 py-2 rounded-lg border-2 border-[#C2C3C8] focus:border-blue-500 focus:outline-none focus:shadow-outline-blue"
                                  type="text"
                                  name="facebook"
                                  placeholder="https://facebook.com/"
                                />
                              </div>
                              <div className="relative w-[50%]">
                                <label className="absolute mt-[-30px]">
                                  LinkedIn
                                </label>
                                <img
                                  src={linkedn}
                                  alt="esdsss"
                                  className="absolute inset-y-3 left-3 h-[] w-[]"
                                />
                                <input
                                  className="w-[100%] pl-10 pr-4 py-2 rounded-lg border-2 border-[#C2C3C8] focus:border-blue-500 focus:outline-none focus:shadow-outline-blue"
                                  type="text"
                                  name="linkedn"
                                  placeholder="https://linkedn.com/in"
                                />
                              </div>
                            </div>
                            <div className="flex space-x-2">
                              <div className="relative w-[50%]">
                                <label className="absolute mt-[-30px]">
                                  Twitter
                                </label>
                                <img
                                  src={twitter}
                                  alt="ewe"
                                  className="absolute inset-y-3 left-3 h-[] w-[]"
                                />
                                <input
                                  className="w-[100%] pl-10 pr-4 py-2 rounded-lg border-2 border-[#C2C3C8] focus:border-blue-500 focus:outline-none focus:shadow-outline-blue"
                                  type="text"
                                  name="twitter"
                                  placeholder="@username"
                                />
                              </div>
                              <div className="relative w-[50%]">
                                <label className="absolute mt-[-30px]">
                                  Instagram
                                </label>
                                <img
                                  src={instagram}
                                  alt="3ewewe"
                                  className="absolute inset-y-3 left-3 h-[] w-[]"
                                />
                                <input
                                  className="w-[100%] pl-10 pr-4 py-2 rounded-lg border-2 border-[#C2C3C8] focus:border-blue-500 focus:outline-none focus:shadow-outline-blue"
                                  type="text"
                                  name="instagram"
                                  placeholder="@username"
                                />
                              </div>
                            </div>
                          </form>
                          <button
                            type="submit"
                            onClick={() => {
                              setStepsCompleted(true);
                            }}
                            className="w-[454px] h-[52px] text-center bg-[#ED1F8E] rounded-[8px] text-[white]"
                          >
                            Proceed
                          </button>
                        </div>
                      </div>
                    </MuiSlide>
                  </div>
                </div>
              </MuiSlide>
              <MuiSlide in={stepsCompleted === true} mountOnEnter unmountOnExit>
                <div className="flex flex-col items-center mb-5">
                  <div className="flex items-center  mt-[40px] mb-5">
                    <h1 className="text-[#040404] text-[18px]">shecluded</h1>
                    <img
                      className="w-[20.32px] h-[40.92px] pb-[7px] ml-[-2px]"
                      src={logo}
                      alt=""
                    />
                  </div>
                  <div className="flex justify-center w-[1201px] bg-[white] h-[540px]">
                    <div className="flex flex-col items-center space-y-5 mt-[6rem]">
                      <div>
                        <img src={checked} alt="" />
                      </div>
                      <div className="text-center font-lato space-y-2">
                        <h1 className="text-[32px] font-semibold">
                          Way to go sis. that was good
                        </h1>
                        <p>Welcome to shecluded</p>
                      </div>
                      <MuiButton
                        onClick={() => {
                          navigate("/customer-dashboard");
                        }}
                        className="w-[454px] h-[52px] flex justify-center items-center bg-[#ED1F8E] rounded-[8px] text-[white]"
                      >
                        Proceed
                      </MuiButton>
                    </div>
                  </div>
                </div>
              </MuiSlide>
            </div>
          </MuiGrid>
        </MuiGrid>
      </MuiGrid>
    )
  );
}

export default Apply;
